import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * Servicio encargado de los datos de Autenticación
 */
@Injectable()
export class AuthenticationService {
  /**
   * Propiedad de control para determinar si se encuentra autenticado
   */
  private isAuthenticated = false;

  /**
   * Propiedad que contiene el access token
   */
  private token = '';

  /**
   * Gets the is authenticated
   */
  public getAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * @description Método para hacer establecer si es autenticado
   * @param isAuthenticated Valor booleano a establecer
   */
  public setAuthenticated(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
  }

  /**
   * Valor del token a establecer
   */
  public set setToken(token: string) {
    this.token = token;
  }

  /**
   * Inicializacion de propiedades
   */
  public constructor() {
    this.isAuthenticated = false; // Inicializa aquí si es necesario
    this.token = ''; // Inicializa aquí si es necesario
  }

  /**
   * Metodo encargado de construir los header para peticiones Http
   * @returns Retorna un objeto HttpHeaders con el token y content-type
   */
  public getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();

    headers = headers.set('Content-Type', 'application/json');

    return this.appendAuthorizationHeader(headers);
  }

  /**
   * Metodo encargado de asociar el token al header Authorization
   * @param headers Objeto al cual se le asociara el header Authorization con el token
   * @returns Retorna el objeto HttpHeaders con el encabezado Authorization
   */
  public appendAuthorizationHeader(headers: HttpHeaders): HttpHeaders {
    if (this.token === '') {
      return headers;
    }

    const tokenValue = `Bearer ${this.token}`;

    return headers.set('Authorization', tokenValue);
  }
}
