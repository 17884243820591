import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CaptchaDto, InteractionDto, ReCaptchaDto } from '@bdo/interaction';
import { environment } from 'apps/form-contact/src/environments/environment';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { DialogPopupComponent } from '../../../core/components/dialog-popup/dialog-popup.component';
import { CoreFacade } from '../../../core/core.facade';
import { TypeAttach } from '../../../core/models/type-attach';
import { DataTransferService } from '../../../services/data-transfer.service';
import { TealiumUtagService } from '../../../tealium/utag.service';
import { DataModel } from '../../models/data-model';
import { FileData } from '../../models/file-data';
import { Interaction } from '../../models/interaction-model';

/**
 * FilesCaptchaComponent permite adjuntar archivos y aceptar el captcha
 */
@Component({
  selector: 'app-files-captcha',
  templateUrl: './files-captcha.component.html',
  styleUrls: ['./files-captcha.component.scss'],
})
export class FilesCaptchaComponent implements OnInit, OnDestroy {
  /**
   * interactionDto asociado al componente
   */
  @Input() public dataModel!: DataModel;
  /**
   * Tealium
   */
  public readonly tealium: TealiumUtagService;
  /**
   * Servicio encargado de transferir datos entre componentes
   */
  public dataTransferService: DataTransferService;
  /**
   * Spinner Service
   */
  public readonly spinner: NgxSpinnerService;
  /**
   * Facade Core
   */
  public coreFacade: CoreFacade;
  /**
   * Bandera para validar estado de archivos
   */
  public validateFiles = false;
  /**
   * Lista de Archivos seleccionados
   */
  public selectedFiles?: FileList;
  /**
   * Nombre de Campo para Archivos
   */
  public readonly filesFieldname = 'files';
  /**
   * Arreglo con la información de los archivos.
   */
  public files: File[] = [];
  /**
   * Mensaje de alerta de archivos
   */
  public messageValidateFiles = '';
  /**
   * BehaviorSubject para mostrar archivo subido
   */
  private readonly BehaviorSubjectMessageSelectFile = new BehaviorSubject<string>('Seleccione su archivo');
  /**
   * messageSelectFile$ texto para mostrar archivo subido
   */
  public readonly messageSelectFile$ = this.BehaviorSubjectMessageSelectFile.asObservable();
  /**
   * Contiene los controles del formulario
   */
  public formGroupFc: FormGroup;
  /**
   * Diálogo Popup con resultado del envío
   */
  public dialogPopup: MatDialog;
  /**
   * messageButton texto para botón
   */
  public messageButton = 'Subir';
  /**
   * errorTextRequestSubmit: Intento de envío con error
   */
  public errorTextRequestSubmit = false;
  /**
   * visibleInputFile: Variable de control para mostrar Input de tipo File
   */
  public visibleInputFile = true;
  /**
   * Observable con ReCaptcha
   */
  private readonly reCaptcha$: Observable<ReCaptchaDto>;
  /**
   * tryRecaptcha: Intento del Recatcha
   */
  public tryRecaptcha = false;
  /**
   * Método para exponer que el Recaptcha sea válido
   */
  public validRecaptcha$ = new BehaviorSubject(false);
  /**
   * Método para verificar si se muestra Input de tipo File o no.
   */
  public get showInputFile(): boolean {
    return this.visibleInputFile;
  }
  /**
   * Método para desuscribir el recaptcha
   */
  private readonly reCaptchaSubscription: Subscription;
  /**
   * @description Crea una nueva instancia de FilesCaptchaComponent
   * @param formGroupFc Parámetro-Propiedad de tipo FormBuilder (uso interno)
   * @param spinner Injecta la instancia de Spinner
   * @param coreFacade Injecta la instancia de CoreFacade
   * @param dataTransferService para transferir datos entre componentes
   * @param dialogPopup Encargado del popUp al finalizar el envío
   * @param tealium Injecta la instancia de Tealium
   */
  public constructor(
    spinner: NgxSpinnerService,
    coreFacade: CoreFacade,
    formGroupFc: FormBuilder,
    dataTransferService: DataTransferService,
    tealium: TealiumUtagService,
    dialogPopup: MatDialog,
  ) {
    this.spinner = spinner;
    this.tealium = tealium;
    this.dialogPopup = dialogPopup;
    this.coreFacade = coreFacade;
    this.dataTransferService = dataTransferService;

    this.formGroupFc = formGroupFc.group({
      files: [''],
      recaptchaReactive: [null, Validators.required],
    });

    this.reCaptcha$ = this.coreFacade.reCaptcha$();

    dataTransferService.behaviorSubjectTypeAttach.subscribe((response) => this.updateFilesValidators(response));
    this.reCaptchaSubscription = this.reCaptcha$.subscribe((response) => this.doRecaptchaReceivedFO(response));
    this.coreFacade.radicado$().subscribe((response) => {
      dataTransferService.radicado = response;
      this.dialogPopup.open(DialogPopupComponent, {
        disableClose: true,
      });
    });

    this.tealium.setConfig({
      account: environment.tealiumAccount,
      profile: environment.tealiumProfile,
      environment: environment.tealiumEnviroment,
    });
  }

  /**
   * @description A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
   */
  public ngOnInit(): void {
    const typeAttachMode = this.dataModel.IdAttachmentMode as TypeAttach;
    this.updateFilesValidators(typeAttachMode);
  }

  /**
   * Metodo ngOnDestroy
   */
  public ngOnDestroy() {
    this.reCaptchaSubscription.unsubscribe();
  }

  /**
   * Metodo para el cargue de archivos
   * @param event Valor seleccionado
   */
  public onFileChange(event: any) {
    this.validateFiles = false;
    this.selectedFiles = event.target.files as FileList;
    const filesControl = this.formGroupFc.get(this.filesFieldname);

    if (this.selectedFiles && this.selectedFiles.length > 0) {
      let totalSize = this.calculateTotalSize(this.files);

      // Convertir FileList a un array para poder iterar
      const filesArray = Array.from(this.selectedFiles);

      for (const file of filesArray) {
        if (this.isValidFile(file)) {
          if (totalSize + file.size <= 25165824) {
            // Verificar si el tamaño total no excede 25 MB
            totalSize += file.size;
            this.readFileContent(file, filesControl);
          } else {
            this.validateFiles = true;
            this.messageValidateFiles = 'El tamaño total a cargar es mayor a 24 MB';
            break; // Salir del bucle si se excede el tamaño máximo
          }
        } else {
          this.validateFiles = true;
          this.messageValidateFiles = 'Los formatos de archivo deben ser JPG, PDF, TIFF, JPEG';
          break; // Salir del bucle si se encuentra un archivo no válido
        }
      }
    }
  }

  /**
   * Metodo para calcular si el peso total de los archivos es valido
   * @param file Archivos subidos
   */
  public calculateTotalSize(files: FileData[]): number {
    return files.reduce((acc, file) => acc + file.size, 0);
  }

  /**
   * Metodo encargado de validar el formato del archivo
   * @param file Archivos subidos
   */
  public isValidFile(file: File): boolean {
    const allowedFormats = ['pdf', 'jpg', 'jpeg', 'tiff'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (fileExtension) {
      return allowedFormats.includes(fileExtension);
    }

    return false; // Devolver falso si no se puede determinar la extensión del archivo
  }

  /**
   * Función para leer el contenido del archivo seleccionado
   * @param file Archivos subidos
   * @param filesControl Control para adjuntar archivos
   */
  public readFileContent(file: File, filesControl: AbstractControl | null) {
    // Verificar si el archivo con el mismo nombre ya está en la lista de archivos
    const existingFile = this.files.find((f) => f.name.toLowerCase() === file.name.toLowerCase());

    if (existingFile) {
      // Manejar el caso de archivo con el mismo nombre
      this.validateFiles = true;
      this.messageValidateFiles = 'Ya existe un archivo con el mismo nombre en la lista';
    } else {
      const reader = new FileReader();

      reader.onload = () => {
        // Convertir la extensión del archivo a minúsculas antes de añadirlo a la lista
        const fileNameParts = file.name.split('.');
        // Convertimos la extensión en minúsculas
        const extension = fileNameParts.pop()?.toLowerCase();
        // Reconstruimos el nombre base y lo sanitizamos
        const baseName = this.sanitizeFileName(fileNameParts.join('.'));
        // Unimos las partes
        const sanitizedFileName = `${baseName}.${extension}`;
        // Creamos un nuevo objeto File con el mismo contenido
        const sanitizedFile = new File([file], sanitizedFileName, { type: file.type });
        // Lo agregamos al arreglo de Files
        this.files.push(sanitizedFile);
        this.updateFormGroup(filesControl);
      };

      reader.readAsDataURL(file);
    }
  }

  /**
   * Función para actualizar el formulario con los archivos seleccionados
   * @param filesControl variable que permite acceder a los controles de los campos
   */
  public updateFormGroup(filesControl: AbstractControl | null) {
    if (filesControl) {
      if (this.files.length > 0) {
        filesControl.setValue(this.files);
        filesControl.setErrors(null);
        this.updateName();
      } else {
        filesControl.setErrors(null);
      }
      this.updateName();
    }
  }

  /**
   * Función para actualizar el nombre de los archivos cargados
   */
  public updateName() {
    const fileCount = this.files.length;
    const pluralSuffix = fileCount !== 1 ? 's' : '';
    this.BehaviorSubjectMessageSelectFile.next(`Cargaste ${fileCount} archivo${pluralSuffix}`);
  }

  /**
   * Restablecer el estado de los archivos a su forma inicial
   */
  public resetFileState() {
    // Restablecer el estado a su forma inicial
    this.validateFiles = false;
    this.files = [];

    const filesControl = this.formGroupFc.get(this.filesFieldname);
    if (filesControl) {
      filesControl.setValue([]);
      filesControl.updateValueAndValidity();
    }
  }

  /**
   * Función para eliminar un archivo de la lista de archivos cargados
   */
  public removeFile(index: number) {
    this.files.splice(index, 1);
    const filesControl = this.formGroupFc.get(this.filesFieldname);
    this.updateFormGroup(filesControl);

    // Actualizar el nombre después de la eliminación
    this.updateName();

    // Verificar si la lista de archivos está vacía después de la eliminación
    if (this.files.length <= 0) {
      this.BehaviorSubjectMessageSelectFile.next('No hay archivos seleccionados');
      this.resetFileState();
    } else {
      this.updateName();
    }
  }

  /**
   * @description Método para verificar cuales controles son inválidos
   */
  public findInvalidControls(): string[] {
    const invalidControls: string[] = [];
    for (const name of Object.keys(this.formGroupFc.controls)) {
      if (this.formGroupFc.controls[name].invalid) {
        invalidControls.push(name);
      }
    }

    return invalidControls;
  }

  /**
   * @description Método para actualizar los validators para el campo files del formulario
   * @param typeAttach Argumento con la información para establecer o remover las validaciones
   */
  private updateFilesValidators(typeAttach: TypeAttach) {
    const filesControl = this.formGroupFc.get(this.filesFieldname);
    filesControl?.clearAsyncValidators();
    switch (typeAttach) {
      case TypeAttach.Required: {
        this.visibleInputFile = true;
        filesControl?.setValidators([Validators.required, Validators.minLength(5)]);
        break;
      }
      case TypeAttach.Optional: {
        this.visibleInputFile = true;
        break;
      }
      case TypeAttach.Disabled: {
        this.visibleInputFile = false;
        filesControl?.setValidators([Validators.maxLength(1)]);
        break;
      }
      default: {
        this.visibleInputFile = false;
        filesControl?.clearAsyncValidators();
      }
    }
    filesControl?.updateValueAndValidity();
  }

  /**
   * Función para eliminar o modificar caracteres especiales del nombre del archivo
   * @param fileName Nombre original del archivo
   * @returns Nombre del archivo sanitizado
   */
  public sanitizeFileName(fileName: string): string {
    // Reemplazar caracteres especiales por guiones bajos o eliminar espacios
    return fileName
      .replace(/[^a-zA-Z0-9.\-_]/g, '_')
      .replace(/\s+/g, '_')
      .replace(/_+/g, '_');
  }

  /**
   * Método encargado de envia el formulario
   */
  public onSubmit(): boolean {
    this.spinner.show();
    parent.window.utag.link({
      tealium_event: 'click',
      eventLabel: 'enviar',
      eventCategory: 'formulario_nexa',
    });

    const invalidControls = this.findInvalidControls();
    this.errorTextRequestSubmit = false;
    if (invalidControls.includes('textRequest')) {
      this.errorTextRequestSubmit = true;
    }

    if (invalidControls.length > 0) {
      this.spinner.hide();

      return false;
    }

    const data: CaptchaDto = {
      googletoken: this.formGroupFc.value.recaptchaReactive,
    };

    this.coreFacade.postRecaptcha(data);

    return true;
  }

  /**
   * Método encargado de validar el captcha
   */
  public doRecaptchaReceivedFO(reCaptcha: ReCaptchaDto) {
    this.tryRecaptcha = true;
    this.validRecaptcha$.next(reCaptcha.success || false);
    if (reCaptcha.isValidAuthentication) {
      // Se agrega el adjunto
      this.dataModel = {
        ...this.dataModel,
        Attachments: this.files.length > 0 ? true : false,
        CountAttachments: this.files.length,
      };

      // Asign a un valor a flagFidu para desplegar el popUp correspondiente
      this.dataTransferService.flagFidu =
        this.dataModel.TypeRequest === 'Queja anónima' || this.dataModel.TypeRequest === 'Sugerencia/Propuesta' ? 2 : 1;

      // Se realiza del envio de correo
      setTimeout(() => {
        this.coreFacade.saveFileStorage(this.files, (responseFilesInfo) => {
          const interactionModel: Interaction = {
            IdInteraction: 1,
            GuId: Guid.create().toString(),
            Forms: this.dataModel,
            Date: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString(),
            IdMailBox: this.dataModel.IdAttachmentMode,
            IdTemplate: this.dataModel.IdTemplate,
            IdAttachmentMode: this.dataModel.IdAttachmentMode,
            Attachment: TypeAttach[Number(this.dataModel.Attachment)],
            Files: responseFilesInfo,
          };

          const interactionDto: InteractionDto = {
            payload: `${JSON.stringify(interactionModel)}`.trim(),
            version: '1',
            idSequence: 2,
            idFiling: 1,
            publishDate: new Date(),
            typeForm: 1,
          };

          this.coreFacade.saveInteraction(interactionDto, (responseInteraction) => {
            if (responseInteraction !== 'false') {
              this.spinner.hide();
            }
          });
        });
      }, 100);
    }
  }
}
