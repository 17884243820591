<div class="padding-form">
  <h2 class="headline-3 text-primary ml-1 my-1 text-uppercase">Trabajamos para darte</h2>
  <h3 class="sub-title-1 text-secondary font-weight-bold ml-1 text-uppercase">Un mejor servicio</h3>
  <h4 class="sub-title-2 weight-400 font-italic ml-1 text-primary mb-3">* Todos los campos son obligatorios</h4>

  <div fxLayout="column">
    <!--Compañías-->
    <mat-form-field fxFlex="100%" hideRequiredMarker>
      <div class="form-group active">
        <mat-label class="label">Compañía</mat-label>
        <mat-select (selectionChange)="companySelected($event.value)">
          <mat-option *ngFor="let company of companies" [value]="company.id">
            {{ company.name }}
          </mat-option>
        </mat-select>
      </div>
    </mat-form-field>
  </div>
  <app-json-form *ngIf="companyId === 1" [jsonFormData]="formData" [companyId]="companyId"></app-json-form>
  <app-json-form-fo *ngIf="companyId === 2" [jsonFormData]="formData" [companyId]="companyId"></app-json-form-fo>
</div>
