import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * Directiva para inhabilitar controles
 */
@Directive({
  selector: '[disableControl]',
})
export class DisableControlDirective {
  /**
   * Método para establecer si el componente es inhabilitado o no
   *
   * @param condition Valor booleano a establecer
   */
  @Input() set disableControl(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    if (this.ngControl.control) {
      this.ngControl.control[action]();
    }
  }
  /**
   * @description Crea una nueva instancia de DisableControlDirective
   *
   * @param ngControl Parámetro-Propiedad de tipo NgControl (uso interno)
   */
  public constructor(private ngControl: NgControl) {}
}
