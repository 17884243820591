import { Component } from '@angular/core';

import { DataTransferService } from '../../../services/data-transfer.service';

/**
 * Componente Angular
 */
@Component({
  selector: 'app-dialog-popup',
  templateUrl: './dialog-popup.component.html',
  styleUrls: ['./dialog-popup.component.scss'],
})
/**
 * Clase de DialogoPopupComponet
 */
export class DialogPopupComponent {
  /**
   * Servicio encargado de transferir datos entre componentes
   */
  public dataTransferService: DataTransferService;

  public constructor(dataTransferService: DataTransferService) {
    this.dataTransferService = dataTransferService;
  }

  /**
   * Método para mostrar el consecutivo en el diálogo
   */
  public get radicado(): string {
    return this.dataTransferService.radicado;
  }

  /**
   * Método para mostrar el consecutivo en el diálogo
   */
  public get responseDays(): number {
    return this.dataTransferService.responseDays;
  }

  /**
   * Método para mostrar un popup u otro
   */
  public get flagFidu(): number {
    return this.dataTransferService.flagFidu;
  }

}
