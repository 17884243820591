import { ExtendedEnvironment } from '../app/dynamic-form/models/extended-enviroments.models';

/**
 * Configuración del entorno
 */
export const environment: ExtendedEnvironment = {
    production: true,
    googleCaptcha: '6LfrSrUeAAAAAASLKkHTxmECBaCRQMiHhmeVN0ge',
    signingAesKey: 'F3gLZRh7Z7meR7hQx3f1SOrfgGPxMaGE',
    tealiumAccount: 'adl',
    tealiumProfile: 'occidente',
    tealiumEnviroment: 'prod',
    occidenteIdCampaign:30223,
    occidenteIdClient:8,
    fiduoccidenteIdCampaign:30224,
    fiduoccidenteIdClient:10021,
    identityServer: {
    },
    apis: {
      sts: 'https://identity-server-api.staging.nexabpo.com/api',
      acl: 'undefined',
      user: 'https://contactenos.bancodeoccidente.com.co/user/api',
      typology: 'https://contactenos.bancodeoccidente.com.co/typology/api',
      interaction: 'https://contactenos.bancodeoccidente.com.co/interaction/api',
      'send-email': 'https://contactenos.bancodeoccidente.com.co/send-email/api',
      carrousel : 'https://contactenos.bancodeoccidente.com.co/carrousel/api',
      'request-url': 'https://contactenos.bancodeoccidente.com.co/request-url/api',
      outbound:'https://core-nexaplus.nexabpo.com/out-product/api',
      file:'https://contactenos.bancodeoccidente.com.co/files/api'
    },
    environmentName: 'Production',
};
