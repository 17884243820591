import { Injectable } from '@angular/core';

/**
 * Servicio Utag que maneja la configuración y el seguimiento de eventos en Tealium.
 */
@Injectable()
export class TealiumUtagService {
  /**
   * URL del script que se carga desde Tealium.
   * Este script es necesario para la integración con el sistema de gestión de etiquetas.
   */
  public scriptSrc = '';

  // Constructor que inicializa las configuraciones de Tealium.
  public constructor() {
    (window as any).utag_cfg_ovrd = { noview: true }; // Configura el modo "noview" para aplicaciones de una sola página (SPA).
    (window as any).utag_data = {}; // Inicializa el objeto utag_data.
  }

  // Método genérico para cargar scripts con un callback.
  public getScript(src: string, callback: () => void) {
    const d = document;
    const fn = () => {};
    const o = { callback: callback || fn };
    let s: any;
    let t: any;

    if (typeof src === 'undefined') {
      return; // Si no se proporciona una fuente, salir del método.
    }

    s = d.createElement('script');
    s.language = 'javascript';
    s.type = 'text/javascript';
    s.async = 1;
    s.charset = 'utf-8';
    s.src = src;

    if (typeof o.callback === 'function') {
      if (!d.addEventListener) {
        // Soporte para versiones antiguas de IE
        s.onreadystatechange = function() {
          if (this.readyState === 'complete' || this.readyState === 'loaded') {
            this.onreadystatechange = null;
            o.callback();
          }
        };
      } else {
        s.addEventListener(
          'load',
          () => {
            o.callback();
          },
          false,
        );
      }
    }

    t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t); // Inserta el nuevo script en el DOM.
  }

  /**
   * Método para establecer las configuraciones del utag.
   * @param config Objeto que contiene la cuenta, perfil y entorno para configurar el utag.
   */
  public setConfig(config: { account: string; profile: string; environment: string }) {
    if (config.account !== undefined && config.profile !== undefined && config.environment !== undefined) {
      this.scriptSrc =
        'https://tags.tiqcdn.com/utag/' + config.account + '/' + config.profile + '/' + config.environment + '/utag.js';
    }
  }

  /**
   * Método para rastrear eventos en Tealium.
   * @param tealiumEvent Nombre del evento a rastrear.
   * @param data Datos opcionales asociados con el evento.
   */
  public track(tealiumEvent: string, data?: any) {
    if (this.scriptSrc === '') {
      return; // Si scriptSrc no está configurado, salir del método.
    }

    if ((window as any).utag === undefined) {
      this.getScript(this.scriptSrc, () => {
        (window as any).utag.track(tealiumEvent, data);
      });
    } else {
      (window as any).utag.track(tealiumEvent, data);
    }
  }

  /**
   * Método para registrar una vista en Tealium.
   * @param data Datos opcionales asociados con la vista.
   */
  public view(data?: any) {
    this.track('view', data);
  }

  /**
   * Método para registrar un enlace en Tealium.
   * @param data Información opcional asociada con el enlace.
   */
  public link(data?: any) {
    this.track('link', data);
  }
}
