<div *ngIf="this.dataTransferService.flagFidu===2">
  <h1 mat-dialog-title>¡Solicitud enviada!</h1>
  <div mat-dialog-content><i>Tu solicitud ha sido enviada exitosamente.</i></div>
  <div mat-dialog-content><i>Si tienes dudas o requerimientos adicionales puedes comunicarte a nuestras Líneas de servicio al cliente en Bogotá: (601) 742 74 45, en el resto del País: 01 8000 189 818.</i></div>
  <div mat-dialog-actions>
    <a href="https://www.fiduoccidente.com/" mat-button  >Entendido</a>
  </div>
</div>
<div *ngIf="this.dataTransferService.flagFidu===1">
  <h1 mat-dialog-title>¡Solicitud enviada!</h1>
  <div mat-dialog-content>Tu solicitud ha sido enviada con número de radicado: <b>{{ radicado }}</b>.</div>
  <div mat-dialog-content>El tiempo de respuesta para tu solicitud es de <b>{{ responseDays }}</b> días hábiles.</div>
  <div mat-dialog-content><i>Por favor conserva el número de radicado a fin de que puedas hacerle seguimiento, contactándonos a través de nuestras líneas de servicio al cliente, una vez cumplido el tiempo de respuesta.</i></div>
  <div mat-dialog-content><i>Líneas de servicio al cliente en Bogotá: (601) 742 74 45, en el resto del País: 01 8000 189 818.</i></div>

  <div mat-dialog-actions>
    <a href="https://www.fiduoccidente.com/" mat-button  >Entendido</a>
  </div>
</div>
<div *ngIf="this.dataTransferService.flagFidu===0">
  <h1 mat-dialog-title >¡Solicitud enviada!</h1>
  <div mat-dialog-content>Estimando cliente tu caso ha quedado radicado con el siguiente número <b>{{ radicado }}</b></div>
   <div mat-dialog-actions>
    <a href="https://www.bancodeoccidente.com.co/" mat-button  >Entendido</a>
  </div>
</div>

