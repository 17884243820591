import { Component, OnInit } from '@angular/core';

/**
 * Componente Angular
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
/**
 * Clases FooterComponent
 */
export class FooterComponent implements OnInit {
  public ngOnInit(): void {}
}
