import { Injectable, Injector, OnDestroy } from '@angular/core';
import { CarrouselApiService } from '@bdo/carrousel';
import { CaptchaApiService, CaptchaDto, InteractionApiService, InteractionDto } from '@bdo/interaction';
import { RequestUrlApiService } from '@bdo/request-url';
import { SegmentationApiService, SendEmailApiService, SendEmailDto } from '@bdo/send-email';
import { CompanyApiService, TypeClientApiService, TypeClientDto, TypologyApiService } from '@bdo/typology';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { FileEmail } from '../dynamic-form/models/file-email';
import { AuthenticationService } from '../services/authentication.service';
import { FileService } from '../shared/services/file-server/file-server.service';

import { CoreState } from './state/core.state';

/**
 * CoreFacade
 */
@Injectable()
export class CoreFacade implements OnDestroy {
  /**
   * Crea una nueva instancia de CoreFacade
   * @param state Administra el estado del modulo Template
   * @param injector Injector
   */
  public constructor(
    injector: Injector,
    companyApiService: CompanyApiService,
    typeClientApiService: TypeClientApiService,
    typologyApiService: TypologyApiService,
    captchaApiService: CaptchaApiService,
    sendEmailApiService: SendEmailApiService,
    authenticationService: AuthenticationService,
    requestUrlApiService: RequestUrlApiService,
    carrouselApiService: CarrouselApiService,
    segmentationApiService: SegmentationApiService,
    interactionApiService: InteractionApiService,
    fileService: FileService,
  ) {
    this.companyApiService = companyApiService;
    this.typeClientApiService = typeClientApiService;
    this.typologyApiService = typologyApiService;
    this.captchaApiService = captchaApiService;
    this.sendEmailApiService = sendEmailApiService;
    this.authenticationService = authenticationService;
    this.requestUrlApiService = requestUrlApiService;
    this.carrouselApiService = carrouselApiService;
    this.segmentacionApiService = segmentationApiService;
    this.interactionApiService = interactionApiService;
    this.fileService = fileService;
    this.state = injector.get(CoreState);
  }
  /**
   * Observable para dar de baja las sucripciones
   */
  public destroy$ = new Subject<boolean>();
  /**
   * Administra el estado del modulo Core
   */
  private readonly state: CoreState;
  /**
   * Api que interactua con el servicio Company
   */
  private readonly companyApiService: CompanyApiService;
  /**
   * Api que interactua con el servicio TypeClient
   */
  private readonly typeClientApiService: TypeClientApiService;
  /**
   * Api que interactua con el servicio Typology
   */
  private readonly typologyApiService: TypologyApiService;
  /**
   * Api que interactua con el servicio Captcha
   */
  private readonly captchaApiService: CaptchaApiService;
  /**
   * Api que interactua con el servicio Segmentacion
   */
  private readonly segmentacionApiService: SegmentationApiService;
  /**
   * Api que interactua con el servicio SendEmail
   */
  private readonly sendEmailApiService: SendEmailApiService;
  /**
   * Servicio encargado de administrar la autenticación
   */
  private readonly authenticationService: AuthenticationService;
  /**
   * Servicio encargado de administrar la autenticación
   */
  private readonly interactionApiService: InteractionApiService;
  /**
   * Servicio encargado de interactuar con el microservicio de index georeferencia
   */
  private readonly requestUrlApiService: RequestUrlApiService;
  /**
   * Servicio encargado de interactuar con el microservicio de index georeferencia
   */
  private readonly carrouselApiService: CarrouselApiService;
  /**
   * Servicio encargado de interactuar con el FileServer
   */
  private readonly fileService: FileService;
  /**
   * Observable con Request Url
   */
  public readonly requestUrl$ = () => this.state.requestUrl$;
  /**
   * Observable con Carrousel
   */
  public readonly carrousel$ = () => this.state.carrousel$;
  /**
   * Observable con Company
   */
  public readonly companies$ = () => this.state.companies$;
  /**
   * Observable con TypeClient
   */
  public readonly typeClients$ = () => this.state.typeClients$;
  /**
   * Observable con Typology
   */
  public readonly typologies$ = () => this.state.typologies$;
  /**
   * Observable con ReCaptcha
   */
  public readonly reCaptcha$ = () => this.state.reCaptcha$;
  /**
   * Observable con ReCaptcha Gerente Elite
   */
  public readonly reCaptchaGe$ = () => this.state.reCaptchaGe$;
  /**
   * Observable del token auth
   */
  public readonly token$ = () => this.state.token$;
  /**
   * Observable del Radicado
   */
  public readonly radicado$ = () => this.state.radicado$;
  /**
   * Al destruirse la instancia de la fachada
   */
  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.state.setCompanies([]);
    this.state.setTypeClients([]);
    this.state.setReCaptchGe({});
    this.state.setReCaptcha({});
  }

  /**
   * Metodo encargado de consultar las Compañías
   */
  public getCompanies(fn: () => void) {
    this.companyApiService
      .all()
      .pipe(
        map((data) => data.filter((x) => x.state === true)),
        takeUntil(this.destroy$),
      )
      .subscribe((response) => {
        this.state.setCompanies(response);
        fn();
      });
  }

  /**
   * Metodo encargado de consultar los Tipos de Clientes
   */
  public getTypeClients(fn: () => void) {
    this.typeClientApiService
      .all()
      .pipe(
        map((data) => data.filter((x) => x.state === true)),
        takeUntil(this.destroy$),
      )
      .subscribe((response) => {
        this.state.setTypeClients(response);
        fn();
      });
  }

  public setTypeClients(type: TypeClientDto[]) {
    this.state.setTypeClients(type);
  }

  /**
   * Metodo encargado de consultar las Tipologías
   */
  public getTypologies(idCompany?: number, idTypeClient?: number) {
    this.state.setTypologies([]);
    this.typologyApiService
      .all()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (idCompany && idTypeClient) {
          const output = response.filter((e) => e.idCompany === idCompany && e.idTypeClient === idTypeClient);
          this.state.setTypologies(output);
        }
      });
  }

  /**
   * Metodo encargado de consultar las Tipología y retornar la información
   */
  public getTypologiesGe(idCompany?: number, idTypeClient?: number) {
    this.state.setTypologies([]);

    return this.typologyApiService.all().pipe(
      takeUntil(this.destroy$),
      map((response) => {
        if (idCompany && idTypeClient) {
          return response.filter((e) => e.idCompany === idCompany && e.idTypeClient === idTypeClient);
        }

        return response;
      }),
    );
  }

  /**
   * Metodo encargado de consultar si es  cliente o no
   */
  public validateClient(idCompany: number, document: string, fn: (data: string) => void) {
    this.state.setTypologies([]);
    this.segmentacionApiService
      .GetDocumentState(document, idCompany)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: string) => {
        fn(response);
      });
  }

  /**
   * Metodo encargado de Enviar Captcha
   */
  public postRecaptcha(data: CaptchaDto) {
    this.captchaApiService
      .postCaptcha(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.state.setReCaptcha(response);
        this.state.setToken(response.authenticationToken || '');
        this.authenticationService.setAuthenticated(response.isValidAuthentication || false);
        this.authenticationService.setToken = response.authenticationToken || '';
      });
  }

  /**
   * Metodo encargado de Enviar Captcha
   */
  public postRecaptchaGe(data: CaptchaDto) {
    this.captchaApiService
      .postCaptcha(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.state.setReCaptchGe(response);
        this.state.setToken(response.authenticationToken || '');
        this.authenticationService.setAuthenticated(response.isValidAuthentication || false);
        this.authenticationService.setToken = response.authenticationToken || '';
      });
  }

  /**
   * Metodo encargado de Enviar Datos de Correo
   */
  public postSendEmail(data: SendEmailDto, fn: () => void) {
    this.sendEmailApiService
      .PostSendEmail(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response.radicado) {
          const radicado = `${data.radicado}`;
          this.state.setRadicado(radicado);
          fn();
        }
      });
  }

  /**
   * Metodo encargado de  traer  las urls de las redes  sociales
   */
  public getRequestUrl(fn: () => void) {
    this.requestUrlApiService
      .all()
      .pipe(
        map((data) => data.filter((x) => x.state === true)),
        takeUntil(this.destroy$),
      )
      .subscribe((response) => {
        this.state.setRedesSociales(response);
        fn();
      });
  }

  /**
   * Metodo encargado de  traer  las imagenes de carrousel
   */
  public getCarrousel(fn: () => void) {
    this.carrouselApiService
      .all()
      .pipe(
        map((data) => data.filter((x) => x.state === true)),
        takeUntil(this.destroy$),
      )
      .subscribe((response) => {
        this.state.setCarrousel(response);
        fn();
      });
  }

  public saveFileStorage(files: File[], fn: (result: string) => void) {
    if (files.length > 0) {
      const filesEmail: FileEmail[] = [];
      this.fileService.PostUpdateFiles(files)
      .subscribe((response) => {
        response.forEach((item) => {
          const fileEmail: FileEmail = {
            name: item.fileInfo?.name,
            uriDownload: item.fileInfo?.path?.uriDownload,
          };
          filesEmail.push(fileEmail);
        });
        fn(JSON.stringify(filesEmail));
      });
    } else {
      fn('');
    }
  }

  /**
   * Metodo encargado de guardar la interación
   * @param interaction Representa la informacion de InteractionDto
   */
  public saveInteraction(interaction: InteractionDto, fn: (flag: string) => void) {
    this.interactionApiService
      .create(interaction)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          if (response.id) {
            const radicado = response.radicado || '';
            this.state.setRadicado(radicado);
            fn(radicado);
          } else {
            fn('false');
          }
        },
        () => {
          fn('false');
        },
      );
  }
}
