<form [formGroup]="formGroupFo">
  <!--Inicio Plantilla de Alert-->

  <ng-template #Alert let-warningOptions="warningOptions">
    <div *ngIf="warningOptions" class="alert">
      <div *ngIf="warningOptions?.showIconAlert" class="icon-alert">
        <i class="bocc-alert-triangle"></i>
      </div>

      <div *ngIf="warningOptions?.texts" class="alert-caption">
        <p *ngFor="let paragraph of warningOptions?.texts" [innerHTML]="paragraph"></p>
      </div>

      <div *ngIf="warningOptions?.showCloseAlert" class="icon-close">
        <a class="btn-nano-gray-light">
          <i class="bocc-x"></i>
        </a>
      </div>
    </div>
  </ng-template>

  <!--Fin Plantilla de Alert-->

  <div fxLayout="column">
    <!--¿Es anónimo?-->
    <mat-form-field fxFlex="100%" hideRequiredMarker>
      <div class="form-group active">
        <mat-label class="label">¿Tu solicitud es anónima?</mat-label>
        <mat-select formControlName="changeAnonymous" (selectionChange)="flagAnonymous()">
          <mat-option [value]="true"> Si </mat-option>
          <mat-option [value]="false"> No </mat-option>
        </mat-select>
      </div>
    </mat-form-field>
  </div>
  <div *ngIf="isAnonymous == true" fxLayout="column">
    <!--Tipo de solicitud-->
    <mat-form-field fxFlex="100%" hideRequiredMarker>
      <div class="form-group active">
        <mat-label class="label">Tipo de solicitud *</mat-label>
        <mat-select formControlName="typeRequest" (selectionChange)="flagDescriptionTypeRequest($event)">
          <mat-option value="Queja anónima"> Queja anónima </mat-option>
        </mat-select>
      </div>
    </mat-form-field>

    <!--Mensaje de ayuda-->
    <div *ngIf="isTypeRequest == true" class="alert">
      <!--Queja anónima-->
      <p>
        Es la manifestación de molestia, inconformidad o insatisfacción que formula una persona natural o jurídica por
        productos y servicios adquiridos, ofrecidos o prestados por una entidad vigilada por la Superintendencia
        Financiera de Colombia. Esta acción, no exige que el denunciante revele su identidad o se pueda identificar
        alguna característica de la persona.
      </p>
    </div>

    <!-- 'Request'-->
    <mat-form-field fxFlex="100%" hideRequiredMarker>
      <div class="form-group active">
        <mat-label class="label">¿Cómo te podemos ayudar? *</mat-label>
        <textarea
          class="mat-input-element-textarea"
          matInput
          rows="3"
          formControlName="textRequest"
          minlength="10"
          maxlength="750"
          placeholder="Escribe tu solicitud aquí Min(10)-Máx(750) caracteres."
          (keyup)="countLetters()"
          [ngClass]="{
            'invalid-textarea': formGroupFo.get('textRequest')?.invalid && formGroupFo.get('textRequest')?.touched
          }"
        >
        </textarea>

        <!-- Contador -->
        <div class="count-char">
          <span>{{ this.valueDescription }}|750</span>
        </div>
        <div
          *ngIf="formGroupFo.get('textRequest')?.invalid && formGroupFo.get('textRequest')?.touched"
          class="alert-message alert-text"
        >
          Por favor, ingresa como mínimo 10 caracteres.
        </div>
      </div>
    </mat-form-field>

    <div *ngIf="formGroupFo.valid">
      <app-files-captcha [dataModel]="dataModel"> </app-files-captcha>
    </div>
  </div>

  <div *ngIf="isAnonymous == false" fxLayout="column">
    <!--Tipo De Cliente-->
    <mat-form-field fxFlex="100%" hideRequiredMarker>
      <div class="form-group active">
        <mat-label class="label">Tipo de Cliente *</mat-label>
        <mat-select
          formControlName="typeClient"
          placeholder="Tipo de Cliente"
          [required]="formGroupFo.controls.typeClient.invalid"
          (selectionChange)="onTypeClientChange($event.value)"
        >
          <mat-option *ngFor="let typeClient of typeClients$ | async" [value]="typeClient.id">
            {{ typeClient.name }}
          </mat-option>
        </mat-select>
      </div>
    </mat-form-field>

    <ng-container *ngFor="let control of jsonFormData?.controls; let i = index">
      <!-- 'select' -->
      <app-selects
        *ngIf="control.type === 'select' && control.name"
        [siblingsControls]="jsonFormData.controls"
        [control]="control"
        [visible]="true"
        (controlAdded)="onAddControl($event)"
        [formBuilder]="formBuilderFo"
        (click)="validateSuggestion()"
      >
      </app-selects>

      <div fxLayout="column">
        <!-- Alerta -->
        <ng-container
          *ngIf="control.type === 'alert' && control.value"
          [ngTemplateOutlet]="Alert"
          [ngTemplateOutletContext]="{
            warningOptions: control.alert
          }"
        >
        </ng-container>

        <!-- 'text','password','email','number','search','tel','url'-->
        <mat-form-field
          *ngIf="['text', 'password', 'email', 'number', 'search', 'tel', 'url'].includes(control.type)"
          fxFlex="100%"
          hideRequiredMarker
        >
          <div class="form-group active">
            <mat-label class="label">{{ control.label }}</mat-label>
            <input
              matInput
              [type]="control.type"
              [formControlName]="control.name"
              [placeholder]="control.label"
              [value]="control.value"
              [disableControl]="control.disabled"
            />
          </div>
        </mat-form-field>

        <!-- 'textarea'-->
        <mat-form-field *ngIf="control.type === 'textarea'" fxFlex="100%" hideRequiredMarker>
          <div class="form-group active">
            <mat-label class="label">{{ control.label }}</mat-label>
            <textarea
              matInput
              rows="3"
              [formControlName]="control.name"
              [placeholder]="control.label"
              minlength="10"
              maxlength="750"
              [value]="control.value"
              [disableControl]="control.disabled"
            ></textarea>
          </div>
        </mat-form-field>
      </div>
    </ng-container>

    <div *ngIf="isSuggestion === true" fxLayout="column">
      <!-- 'Request'-->
      <mat-form-field fxFlex="100%" hideRequiredMarker>
        <div class="form-group active">
          <mat-label class="label">¿Cómo te podemos ayudar? *</mat-label>
          <textarea
            class="mat-input-element-textarea"
            matInput
            rows="3"
            formControlName="textRequest"
            minlength="10"
            maxlength="750"
            placeholder="Escribe tu solicitud aquí Min(10)-Máx(750) caracteres."
            (keyup)="countLetters(); sendData()"
            [ngClass]="{
              'invalid-textarea': formGroupFo.get('textRequest')?.invalid && formGroupFo.get('textRequest')?.touched
            }"
          >
          </textarea>

          <!-- Contador -->
          <div class="count-char">
            <span>{{ this.valueDescription }}|750</span>
          </div>
          <div
            *ngIf="formGroupFo.get('textRequest')?.invalid && formGroupFo.get('textRequest')?.touched"
            class="alert-message alert-text"
          >
            Por favor, ingresa como mínimo 10 caracteres.
          </div>
        </div>
      </mat-form-field>

      <!--Captcha y carga de archivos-->
      <div *ngIf="formGroupFo.valid">
        <app-files-captcha [dataModel]="dataModel"> </app-files-captcha>
      </div>
    </div>
    <!--Cliente Fiduciaria con identificacion -->
    <div *ngIf="isSuggestion === false" fxLayout="column">
      <!--Tipo de documento-->
      <mat-form-field fxFlex="100%" hideRequiredMarker>
        <div class="form-group active">
          <mat-label class="label">Tipo de documento *</mat-label>
          <mat-select formControlName="typeDocument">
            <mat-option *ngIf="formGroupFo.controls.typeClient.value !== 2" value="CC">
              CC - Cédula de Ciudadanía
            </mat-option>
            <mat-option *ngIf="formGroupFo.controls.typeClient.value !== 2" value="CE">
              CE - Cédula de Extranjería</mat-option
            >
            <mat-option *ngIf="formGroupFo.controls.typeClient.value !== 2" value="NUIP">
              NUIP - Número Único de Identificación Personal</mat-option
            >
            <mat-option *ngIf="formGroupFo.controls.typeClient.value !== 2" value="PS"> PS - Pasaporte</mat-option>
            <mat-option *ngIf="formGroupFo.controls.typeClient.value == 2" value="NIT">
              NIT - Número de Identificación Tributaria</mat-option
            >
          </mat-select>
        </div>
      </mat-form-field>

      <!--Documento-->
      <mat-form-field fxFlex="100%" hideRequiredMarker *ngIf="formGroupFo.controls.typeDocument.value !== null">
        <div class="form-group active">
          <mat-label class="label">Número de Documento *</mat-label>
          <input
            class="mat-input-element-input"
            matInput
            type="text"
            pattern="[0-9]*"
            inputmode="numeric"
            minlength="5"
            maxlength="12"
            formControlName="document"
            placeholder="Digitos permitidos Min(5) - Max(12)."
            [required]="formGroupFo.controls.document.invalid"
            (keypress)="numberOnly($event)"
            (change)="validateDocument()"
          />
        </div>
      </mat-form-field>
      <div
        *ngIf="formGroupFo.controls.document.value?.length <= 4 && formGroupFo.controls.document.value?.length > 0"
        style="color: red"
      >
        El número de documento debe tener más de 4 dígitos.
      </div>

      <div *ngIf="isClient === false" class="alert">
        <p>
          Creo que aún no eres nuestro cliente. Por favor verifica que tu número de documento este correcto para poder
          ayudarte, te invitamos por favor a comunicarte a nuestras líneas de atención en Bogotá<a
            href="tel:+576012973060"
          >
            (601) 742 74 45</a
          >
          y para el resto del país al <a href="tel:018000189818">01 8000 189 818</a>
        </p>
        <p><b>Si deseas adquirir productos con nosotros aquí te podemos </b><a href="#">ayudar</a></p>
      </div>

      <div *ngIf="isClient === true" fxLayout="column">
        <!--Nombres y Apellidos-->
        <mat-form-field fxFlex="100%" hideRequiredMarker>
          <div class="form-group active">
            <mat-label class="label">Nombres completo / Razón social *</mat-label>
            <input
              class="mat-input-element-input"
              matInput
              type="text"
              formControlName="name"
              maxlength="128"
              placeholder="(Valor permitido: Nombre Completo)."
              [required]="formGroupFo.controls.name.invalid"
            />
          </div>
        </mat-form-field>

        <!--Medio de respuesta-->
        <mat-form-field fxFlex="100%" hideRequiredMarker *ngIf="formGroupFo.controls.name.value !== ''">
          <div class="form-group active">
            <mat-label class="label">Medio de respuesta a tu PQRSD *</mat-label>
            <mat-select formControlName="meansResponse" (selectionChange)="validateMeansResponse()">
              <mat-option [value]="true"> Correo electrónico </mat-option>
              <mat-option [value]="false"> Dirección física de correspondencia </mat-option>
            </mat-select>
          </div>
        </mat-form-field>

        <div *ngIf="this.selectedMeansResponse == true" fxLayout="column">
          <!--Correo Electronico-->
          <mat-form-field fxFlex="100%" hideRequiredMarker>
            <div class="form-group active">
              <mat-label class="label">Correo Electrónico *</mat-label>
              <input
                class="mat-input-element-input"
                matInput
                type="email"
                formControlName="email"
                maxlength="128"
                placeholder="(Valor  permitido:Ej. pruebas@pruebas.com)."
                [required]="formGroupFo.controls.email.invalid"
                [ngClass]="{ invalid: formGroupFo.get('email')?.invalid && formGroupFo.get('email')?.touched }"
              />
              <!-- Mensaje de alerta -->
              <div
                *ngIf="formGroupFo.get('email')?.invalid && formGroupFo.get('email')?.touched"
                class="alert-message invalid-text"
              >
                Por favor, ingresa un correo electrónico válido.
              </div>
            </div>
          </mat-form-field>
        </div>

        <div *ngIf="this.selectedMeansResponse == false" fxLayout="column">
          <!--País-->
          <mat-form-field fxFlex="100%" hideRequiredMarker>
            <div class="form-group active">
              <mat-label class="label">País *</mat-label>
              <input
                class="mat-input-element-input"
                matInput
                type="text"
                formControlName="country"
                value="Colombia"
                readonly
              />
            </div>
          </mat-form-field>

          <!--Departamentos-->
          <mat-form-field fxFlex="100%" hideRequiredMarker>
            <div class="form-group active">
              <mat-label class="label">Departamento *</mat-label>
              <mat-select
                formControlName="department"
                placeholder="Departamento"
                (selectionChange)="onDepartmentChange($event.value)"
              >
                <mat-option *ngFor="let department of departments$ | async" [value]="department.id">
                  {{ department.name }}
                </mat-option>
              </mat-select>
            </div>
          </mat-form-field>

          <!--Ciudades-->
          <mat-form-field fxFlex="100%" hideRequiredMarker>
            <div class="form-group active">
              <mat-label class="label">Ciudad *</mat-label>
              <mat-select formControlName="city" placeholder="Ciudad">
                <mat-option *ngFor="let city of cities$ | async" [value]="city.name">
                  {{ city.name }}
                </mat-option>
              </mat-select>
            </div>
          </mat-form-field>

          <!--Barrio - Vereda - Corregimiento-->
          <mat-form-field fxFlex="100%" hideRequiredMarker>
            <div class="form-group active">
              <mat-label class="label">Barrio - Vereda - Corregimiento *</mat-label>
              <input
                class="mat-input-element-input"
                matInput
                type="text"
                maxlength="50"
                formControlName="neighborhood"
              />
            </div>
          </mat-form-field>

          <div *ngIf="formGroupFo.controls.neighborhood.value?.length > 50" style="color: red">
            Debe tener menos de 50 caracteres.
          </div>

          <!--Dirección física-->
          <mat-form-field fxFlex="100%" hideRequiredMarker>
            <div class="form-group active">
              <mat-label class="label">Dirección física *</mat-label>
              <input class="mat-input-element-input" matInput type="text" maxlength="50" formControlName="address" />
            </div>
          </mat-form-field>

          <div *ngIf="formGroupFo.controls.address.value?.length > 50" style="color: red">
            Debe tener menos de 50 caracteres.
          </div>
        </div>

        <!--Número de contacto-->
        <mat-form-field fxFlex="100%" hideRequiredMarker *ngIf="formGroupFo.controls.meansResponse.value !== null">
          <div class="form-group active">
            <mat-label class="label">Número de contacto</mat-label>
            <input
              class="mat-input-element-input"
              matInput
              type="text"
              pattern="[0-9]*"
              inputmode="numeric"
              maxlength="10"
              formControlName="numberContact"
              placeholder="Digitos permitidos Min(10) - Max(10)."
              (keypress)="numberOnly($event)"
            />
          </div>
        </mat-form-field>

        <div *ngIf="formGroupFo.controls.numberContact.value?.length > 10" style="color: red">
          El número de documento debe tener 10 dígitos.
        </div>

        <!-- 'Request'-->
        <mat-form-field fxFlex="100%" hideRequiredMarker *ngIf="formGroupFo.controls.meansResponse.value !== null">
          <div class="form-group active">
            <mat-label class="label">¿Cómo te podemos ayudar? *</mat-label>
            <textarea
              class="mat-input-element-textarea"
              matInput
              rows="3"
              formControlName="textRequest"
              minlength="10"
              maxlength="750"
              placeholder="Escribe tu solicitud aquí Min(10)-Máx(750) caracteres."
              (keyup)="countLetters()"
              [ngClass]="{
                'invalid-textarea': formGroupFo.get('textRequest')?.invalid && formGroupFo.get('textRequest')?.touched
              }"
            >
            </textarea>

            <!-- Contador -->
            <div class="count-char">
              <span>{{ this.valueDescription }}|750</span>
            </div>
            <div
              *ngIf="formGroupFo.get('textRequest')?.invalid && formGroupFo.get('textRequest')?.touched"
              class="alert-message alert-text"
            >
              Por favor, ingresa como mínimo 10 caracteres.
            </div>
          </div>
        </mat-form-field>

        <!--Captcha y carga de archivos-->
        <div *ngIf="formGroupFo.valid">
          <app-files-captcha [dataModel]="dataModel"> </app-files-captcha>
        </div>
      </div>
    </div>
  </div>
</form>
