import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { TypeAttach } from '../core/models/type-attach';
import { JsonFormMetadata } from '../dynamic-form/models/json-form-data';

/**
 * Servicio encargado de transferir datos entre componentes
 */
@Injectable()
export class DataTransferService {
  /**
   * showRequest: Variable de control para mostrar solicitud
   */

  public showRequest = false;

  /**
   * Behavior Subject TypeAttach
   */
  public behaviorSubjectTypeAttach = new BehaviorSubject<TypeAttach>(TypeAttach.None);

  /**
   * subject Metadata
   */
  public subjectMetadata = new Subject<JsonFormMetadata>();

  /**
   * radicado: Variable para mostrar el valor consecutivo una vez enviado el formulario
   */
  public radicado = '';

   /**
    * radicado: Variable para mostrar el valor consecutivo una vez enviado el formulario
    */
  public responseDays = 0;

   /**
    * flagFidu: Variable para mostrar un texto u otro de pop up
    */
  public flagFidu = 0;

  /**
   * Documento gerente élite
   */
  public documentGe = new BehaviorSubject<any>(null);

  /**
   * Crea una nueva instancia de @see DataTransferService
   */
  public constructor() {
    this.subjectMetadata.subscribe((response) => this.responseDays = response.responseDays);
  }
}
