import { Component, OnInit } from '@angular/core';

/**
 * Componente Angular
 */
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
/**
 * Clase LayoutComponent
 */
export class LayoutComponent implements OnInit {

  public ngOnInit(): void {

  }
}
