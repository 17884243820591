<form [formGroup]="form">
  <!--Inicio Plantilla de HelpText-->

  <ng-template #HelpText let-warningOptions="warningOptions">
    <div *ngIf="warningOptions" class="alert">
      <div *ngIf="warningOptions?.texts || warningOptions?.textList" class="alert-caption">
        <p *ngFor="let paragraph of warningOptions?.texts" [innerHTML]="paragraph"></p>
        <ul *ngIf="warningOptions?.textList" class="alert-list-nb">
          <li *ngFor="let item of warningOptions?.textList?.texts" class="alert-item-nb"><p [innerHTML]="item"></p></li>
        </ul>
      </div>
    </div>
  </ng-template>

  <!--Fin Plantilla de HelpText-->

  <div fxLayout="column">
    <ng-container *ngIf="control?.children">
      <mat-form-field *ngIf="control.type === 'select' && control.visible" fxFlex="100%" hideRequiredMarker>
        <div class="form-group active">
          <mat-label class="label">{{ control.label }}</mat-label>
          <mat-select
            [formControlName]="control.name"
            [placeholder]="control.label"
            (selectionChange)="onSelectChange($event.value)"
          >
            <mat-option *ngFor="let child of control.children" [value]="child.value">
              {{ child.value }}
            </mat-option>
          </mat-select>
        </div>
      </mat-form-field>
    </ng-container>

    <ng-container *ngFor="let child of control?.children">
      <div fxFlex="100%">
        <app-selects
          *ngIf="child.type === 'select'"
          [parentControl]="control"
          [siblingsControls]="control?.children"
          [control]="child"
          [formBuilder]="formBuilder"
        ></app-selects>
      </div>
    </ng-container>

    <ng-container *ngIf="control.visible && control.metadata">
      <div fxFlex="100%">
        <ng-container
          [ngTemplateOutlet]="HelpText"
          [ngTemplateOutletContext]="{
            warningOptions: control.metadata.helpText
          }"
        >
        </ng-container>
      </div>
    </ng-container>
  </div>
</form>
