
<div class="div-icons">
  <!-- Mal funcionamiento con el Resize / se cambia con @media screen-->
  <!--
    fxLayout.gt-md="row"
    fxLayoutGap.gt-md="70px"
    fxFlexOffset.gt-md="24"
    fxLayoutAlign.gt-md=" center"

    fxLayout.lt-lg="column"
    fxLayoutAlign.lt-lg=" center"
  -->
  <div class="icons">
      <img class="py-2 icons-contact" src="./../../../../assets/img/clock.png" alt="">
      <h3 class="title-text-contact sub-title text-center text-primary text-uppercase">Horario de atención</h3>
      <p class="text-center font-weight-medium">Lunes - Viernes <br> 8:00 AM - 5:00 PM</p>
  </div>
  <div class="icons">
      <img class="py-2 icons-contact" src="./../../../../assets/img/call-center.png" alt="">
      <h3 class="title-text-contact sub-title text-center text-primary weight-400">Banco de occidente</h3>
      <p class="text-center font-weight-medium">Bogotá: (601) 390 2058 <br> En todo el país 01 800 05 14652</p>
  </div>

  <div class="icons icons-contact">
      <img class="py-2" src="./../../../../assets/img/call-center.png" alt="">
      <h3 class="title-text-contact sub-title text-center text-primary weight-400">Fiduciaria</h3>
      <p class="text-center font-weight-medium">Bogotá: (601) 742 7445 <br>Resto del país 01 8000 189 818</p>
  </div>

</div>
