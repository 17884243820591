import { Component, OnInit } from '@angular/core';

import { JsonFormData } from '../../../dynamic-form/models/json-form-data';
import { CoreFacade } from '../../core.facade';

/**
 * FormComponent
 */
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  /**
   * Propiedad que contiene la Typología desde el servicio
   */
  public formData!: JsonFormData;

  /**
   * Facade Core
   */
  public coreFacade: CoreFacade;

  /**
   * @description Crea una nueva instancia de FormComponent
   *
   * @param coreFacade Injecta la instancia de CoreFacade
   */
  public constructor(coreFacade: CoreFacade) {
    this.coreFacade = coreFacade;
  }

  /**
   * método llamado luego de que han inicializados todas las propiedades
   */
  public ngOnInit(): void {
    this.coreFacade.typologies$().subscribe((x) => {
      if (x[0] && x[0].payload && x[0].payload.length > 0) {
        this.formData = JSON.parse(x[0].payload) as JsonFormData;
      }
    });
  }
}
