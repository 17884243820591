
  <div fxLayout="column" >
    <mat-toolbar color="primary">
      <mat-nav-list class="list-horizontal">
        <div fxLayout="row"  fxFlex>
          <mat-list-item class="sf-item" *ngIf="this.youtube != ''" >
            <a href="{{this.youtube}}" target="_black" class="sf-link">
              <i class="bocc-youtube"></i>
            </a>
          </mat-list-item>
          <mat-list-item class="sf-item" *ngIf="this.linkedin !=''">
            <a href="{{this.linkedin}}" target="_black" class="sf-link">
              <i class="bocc-linkedin2"></i>
            </a>
          </mat-list-item>
          <mat-list-item class="sf-item" *ngIf="this.twitter !=''">
            <a href="{{this.twitter}}" target="_black" class="sf-link">
              <i class="bocc-twitter"></i>
            </a>
          </mat-list-item>
          <mat-list-item class="sf-item" *ngIf="this.facebook !=''">
            <a href="{{this.facebook}}" target="_black" class="sf-link">
              <i class="bocc-facebook"></i>
            </a>
          </mat-list-item>
        </div>
      </mat-nav-list>
    </mat-toolbar>
  </div>
  <ngx-spinner bdColor="rgba(0,0,0,0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
    <p class="spinner-text">Cargando...</p>
  </ngx-spinner>

