import { Component, OnInit } from '@angular/core';

import { JsonFormData } from '../../../dynamic-form/models/json-form-data';
import { DataTransferService } from '../../../services/data-transfer.service';
import { CoreFacade } from '../../core.facade';

/**
 * FormComponent
 */
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  /**
   * Propiedad que contiene la Typología desde el servicio
   */
  public formData!: JsonFormData;

  /**
   * Servicio encargado de transferir datos entre componentes
   */
  public dataTransferService: DataTransferService;

  /**
   * Facade Core
   */
  public coreFacade: CoreFacade;

  /**
   * Observable de companies
   */
  public companies: any[] = [];

  /**
   * Id compañia seleccionada
   */
  public companyId = 0;

  /**
   * @description Crea una nueva instancia de FormComponent
   *
   * @param coreFacade Injecta la instancia de CoreFacade
   * @param dataTransferService para transferir datos entre componentes
   */
  public constructor(coreFacade: CoreFacade, dataTransferService: DataTransferService) {
    this.coreFacade = coreFacade;
    this.dataTransferService = dataTransferService;
  }

  /**
   * método llamado luego de que han inicializados todas las propiedades
   */
  public ngOnInit(): void {
    this.coreFacade.typologies$().subscribe((x) => {
      if (x[0] && x[0].payload && x[0].payload.length > 0) {
        this.formData = JSON.parse(x[0].payload) as JsonFormData;
      }
    });
    this.loadCompanies();
  }

  /**
   * Método encargado de cargar las compañias existentes
   */
  public loadCompanies() {
    this.coreFacade.getCompanies(() => {
      this.coreFacade.companies$().subscribe((companies) => {
        this.companies = companies;
      });
    });
  }

  /**
   * Método encargado de asignar el Id de la compañia seleccionada
   */
  public companySelected(id: number) {
    this.companyId = id;
    this.dataTransferService.flagCompanieAlert = id;
  }
}
