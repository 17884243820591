<div *ngIf="this.dataTransferService.flagFidu===1">
  <h1 mat-dialog-title>¡Solicitud enviada!</h1>
  <div mat-dialog-content>Su solicitud ha sido enviada con número de radicado: <b>{{ radicado }}</b>.</div>
  <div mat-dialog-content>El tiempo de respuesta para su solicitud es de <b>{{ responseDays }}</b> días hábiles.</div>
  <div mat-dialog-content><i>Por favor conserve el número de radicado a fin de que pueda hacerle seguimiento una vez cumplido el tiempo de respuesta.</i></div>

  <div mat-dialog-actions>
    <a href="https://www.fiduoccidente.com/" mat-button  >Entendido</a>
  </div>
</div>
<div *ngIf="this.dataTransferService.flagFidu===0">
  <h1 mat-dialog-title >¡Solicitud enviada!</h1>
  <div mat-dialog-content>Estimando cliente tu caso ha quedado radicado con el siguiente número <b>{{ radicado }}</b></div>
   <div mat-dialog-actions>
    <a href="https://www.bancodeoccidente.com.co/" mat-button  >Entendido</a>
  </div>
</div>

