import { Component, OnInit } from '@angular/core';

import { environment } from '../environments/environment';

import { TealiumUtagService } from './tealium/utag.service';

/**
 * Componente de inicio de angular
 */
@Component({
  selector: 'app-root',
  providers: [TealiumUtagService],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  /**
   * Título Inicial
   */
  public title = 'form-contact';
  /**
   * Propidad Tealium
   */
  private readonly tealium: TealiumUtagService;
  /**
   * Constructor de la clase
   * @param tealium Servicio de Tealium para la gestión de etiquetas
   */
  public constructor(tealium: TealiumUtagService) {
    this.tealium = tealium;
    this.tealium.setConfig({ account: environment.tealiumAccount, profile: environment.tealiumProfile, environment: environment.tealiumEnviroment });
  }

  /**
   * Función que se ejecuta al iniciar el componente
   */
  public ngOnInit(): void {
    // The tealium.view function will call the tealium.track function with 'view' as first param
    // Most tags support the 'view' event and many analytics tags also support the 'link' event
    this.tealium.view({event_name: 'init'});
    // This.tealium.track('view', {'event_name' : 'init'});
  }
}
