<nav class="navbar-lg">
  <div>
    <a href="https://www.bancodeoccidente.com.co/">
      <img class="occidente" src="./../../../../assets/img/logo-occidente.png" alt="">
    </a>
    <a href="https://www.fiduoccidente.com/">
      <img class="fiduciaria" src="./../../../../assets/img/logo-fiduciaria.png" alt="">
    </a>
  </div>
</nav>
