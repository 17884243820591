import { Injectable } from '@angular/core';
import { CarrouselDto } from '@bdo/carrousel';
import { ReCaptchaDto } from '@bdo/interaction';
import { RequestUrlDto } from '@bdo/request-url';
import { CompanyDto, TypeClientDto, TypologyDto } from '@bdo/typology';
import { StateBase } from '@nexa/core';
import { BehaviorSubject, Subject } from 'rxjs';

/**
 * CoreState
 */
@Injectable()
export class CoreState extends StateBase<CompanyDto> {
  /**
   * Behavior Subject Company
   */
  private readonly behaviorSubjectCompanies = new BehaviorSubject<CompanyDto[]>([]);

  /**
   * Observable con Company
   */
  public readonly companies$ = this.behaviorSubjectCompanies.asObservable();

  /**
   * Behavior Subject TypeClient
   */
  private readonly behaviorSubjectTypeClients = new BehaviorSubject<TypeClientDto[]>([]);

  /**
   * Observable con TypeClient
   */
  public readonly typeClients$ = this.behaviorSubjectTypeClients.asObservable();

  /**
   * Behavior Subject Typology
   */
  private readonly behaviorSubjectTypologies = new BehaviorSubject<TypologyDto[]>([]);

  /**
   * Observable con Typology
   */
  public readonly typologies$ = this.behaviorSubjectTypologies.asObservable();

  /**
   * Subject ReCaptcha
   */
  private readonly subjectReCaptcha = new Subject<ReCaptchaDto>();
  /**
   * Subject ReCaptcha Gerente Elite
   */
  private readonly subjectReCaptchaGe = new Subject<ReCaptchaDto>();

  /**
   * Observable con ReCaptcha
   */
  public readonly reCaptcha$ = this.subjectReCaptcha.asObservable();

  /**
   * Observable con ReCaptcha GerenteElite
   */
  public readonly reCaptchaGe$ = this.subjectReCaptchaGe.asObservable();

  /**
   * Subject - subjectToken
   */
  private readonly subjectToken = new Subject<string>();

  /**
   * Observable con token
   */
  public token$ = this.subjectToken.asObservable();

  /**
   * Subject - subjectRadicado
   */
  private readonly subjectRadicado = new Subject<string>();

  /**
   * Observable con radicado
   */
  public radicado$ = this.subjectRadicado.asObservable();
  /**
   * Behavior request url
   */
  private readonly behaviorRequestUrl = new BehaviorSubject<RequestUrlDto[]>([]);

  /**
   * Observable con Typology
   */
  public readonly requestUrl$ = this.behaviorRequestUrl.asObservable();

  /**
   * Behavior request url
   */
  private readonly behaviorCarrousel = new BehaviorSubject<CarrouselDto[]>([]);

  /**
   * Observable con Typology
   */
  public readonly carrousel$ = this.behaviorCarrousel.asObservable();

  /**
   * @description Método encargado de establecer el arreglo Company
   *
   * @param companies Compañías
   */
  public setCompanies(companies: CompanyDto[]) {
    this.behaviorSubjectCompanies.next(companies);
  }

  /**
   * @description Método encargado de establecer el arreglo TypeClient
   *
   * @param typeClients Tipos de cliente
   */
  public setTypeClients(typeClients: TypeClientDto[]) {
    this.behaviorSubjectTypeClients.next(typeClients);
  }

  /**
   * @description Método encargado de establecer el arreglo Typology
   *
   * @param typologies Tipologías
   */
  public setTypologies(typologies: TypologyDto[]) {
    this.behaviorSubjectTypologies.next(typologies);
  }

  /**
   * @description Método encargado de establecer el arreglo Redes sociales
   *
   * @param requesturl url Redes sociales
   */
  public setRedesSociales(requesturl: RequestUrlDto[]) {
    this.behaviorRequestUrl.next(requesturl);
  }
  /**
   * @description Método encargado de establecer el arreglo Carrousel
   *
   * @param carrousel imagens de Carrousel
   */
  public setCarrousel(carrousel: CarrouselDto[]) {
    this.behaviorCarrousel.next(carrousel);
  }
  /**
   * @description Método encargado de establecer el ReCaptcha
   *
   * @param recaptcha recaptcha
   */
  public setReCaptcha(recaptcha: ReCaptchaDto) {
    this.subjectReCaptcha.next(recaptcha);
  }

  /**
   * @description Método encargado de establecer el ReCaptcha Ge
   *
   * @param recaptcha recaptcha
   */
  public setReCaptchGe(recaptcha: ReCaptchaDto) {
    this.subjectReCaptchaGe.next(recaptcha);
  }

  /**
   * @description Metodo encargado de asignar el resultado de token JWT
   * @param data Información de la token JWT
   */
  public setToken(token: string) {
    this.subjectToken.next(token);
  }

  /**
   * @description Método encargado de establecer el Radicado
   * @param radicado Información del radicado
   */
  public setRadicado(radicado: string) {
    this.subjectRadicado.next(radicado);
  }
}
