import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { CityDto, CompanyDto, DepartmentDto, TypeClientDto } from '@bdo/typology';
import { environment } from 'apps/form-contact/src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CoreFacade } from '../../../core/core.facade';
import { DataTransferService } from '../../../services/data-transfer.service';
import { EncryptService } from '../../../shared/services/encyrpt/encrypt.service';
import { getValidators } from '../../functions';
import { DataDecryptMe } from '../../models/data-decrypt-me';
import { DataModel } from '../../models/data-model';
import { JsonFormControls, JsonFormData, JsonFormMetadata } from '../../models/json-form-data';
import { ValidateClient } from '../../models/validate-client';

/**
 * JsonFormFoComponent permite crear formularios agregando controles a partir de un JSON
 */
@Component({
  selector: 'app-json-form-fo',
  templateUrl: './json-form-fo.component.html',
  styleUrls: ['./json-form-fo.component.scss'],
})
export class JsonFormFoComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * Servicio encargado de interactuar con el servicio de encriptación
   */
  private readonly encryptService: EncryptService;
  /**
   * Observable con Company
   */
  public readonly companies$: Observable<CompanyDto[]>;
  /**
   * Arreglo con Company
   */
  private companies: CompanyDto[] = [];
  /**
   * Se establece el observable de ciudades
   */
  public cities$!: Observable<CityDto[]>;
  /**
   * Se establece el observable de departamentos
   */
  public departments$!: Observable<DepartmentDto[]>;
  /**
   * Arreglo con Department
   */
  private departments: DepartmentDto[] = [];
  /**
   * Spinner Service
   */
  public readonly spinner: NgxSpinnerService;
  /**
   * Facade Core
   */
  public coreFacade: CoreFacade;
  /**
   * Contiene los controles del formulario
   */
  public formGroupFo: FormGroup;
  /**
   * Creador de Formulario para ser expuesto
   */
  public formBuilderFo: FormBuilder;
  /**
   * Número de caracteres en la descripción
   */
  public valueDescription = 0;
  /**
   * isAnonymous: Variable de control para mostrar el formulario si el cliente es anónimo
   */
  public isAnonymous!: boolean;
  /**
   * isClient: Variable de control para mostrar el formulario si es cliente
   */
  public isClient!: boolean;
  /**
   * isSuggestion: Flag si el tipo de solicitud es Sugerencia
   */
  public isSuggestion!: boolean;
  /**
   * isTypeRequest: Flag para el tipo de solicitud
   */
  public isTypeRequest!: boolean;
  /**
   * selectedMeansResponse
   */
  public selectedMeansResponse!: boolean;
  /**
   * dataModel: Se inicializa dataModel
   */
  public dataModel!: DataModel;
  /**
   * Mínimo valor para documento
   */
  public readonly minValueDocument = 99999;
  /**
   * Servicio encargado de transferir datos entre componentes
   */
  public dataTransferService: DataTransferService;
  /**
   * JsonFormData asociado al componente
   */
  @Input() public jsonFormData!: JsonFormData;
  /**
   * CompanyId asociado al componente
   */
  @Input() public companyId!: number;
  /**
   * Contiene los controles del formulario
   */
  private readonly initialFormControlNames: string[] = [];
  /**
   * Observable con TypeClient
   */
  public readonly typeClients$: Observable<TypeClientDto[]>;
  /**
   * Arreglo con TypeClient
   */
  private typeClients: TypeClientDto[] = [];

  /**
   * @description Crea una nueva instancia de JsonFormFoComponent
   * @param spinner Injecta la instancia de Spinner
   * @param coreFacade Injecta la instancia de CoreFacade
   * @param formGroupFo Parámetro-Propiedad de tipo FormBuilder (uso interno)
   * @param dataTransferService para transferir datos entre componentes
   * @param encryptService Encargado de encriptar información
   */
  public constructor(
    spinner: NgxSpinnerService,
    coreFacade: CoreFacade,
    formBuilder: FormBuilder,
    dataTransferService: DataTransferService,
    encryptService: EncryptService,
  ) {
    this.spinner = spinner;
    this.encryptService = encryptService;
    this.coreFacade = coreFacade;
    this.formBuilderFo = formBuilder;
    this.dataTransferService = dataTransferService;

    this.companies$ = this.coreFacade.companies$();
    this.typeClients$ = this.coreFacade.typeClients$();
    this.departments$ = this.coreFacade.departmentsAll();

    dataTransferService.subjectMetadata.subscribe((response) => this.setMetadataFields(response));
    this.typeClients$.subscribe((response) => (this.typeClients = response));
    this.companies$.subscribe((response) => (this.companies = response));
    this.departments$.subscribe((response) => (this.departments = response));

    this.formGroupFo = this.formBuilderFo.group({
      // Anónimo
      changeAnonymous: [''],
      typeRequest: [''],
      textRequest: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(750)]],

      // No anónimo

      name: [''],
      typeDocument: [''],
      typeClient: [''],
      document: ['', [Validators.minLength(5), Validators.maxLength(12)]],
      meansResponse: [null],
      country: ['Colombia'],
      city: [''],
      department: [''],
      neighborhood: ['', [Validators.maxLength(50)]],
      address: ['', [Validators.maxLength(50)]],
      numberContact: ['', [Validators.minLength(10), Validators.maxLength(10)]],
      email: [
        '',
        [
          Validators.email,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.(com|org|net|edu|gov|mil|info|io|co|me|biz|xyz|us|uk|es|fr|de|jp|cn|br|au|ca|COM|ORG|NET|EDU|GOV|MIL|INFO|IO|CO|ME|BIZ|XYZ|US|UK|ES|FR|DE|JP|CN|BR|AU|CA)$',
          ),
        ],
      ],
      idTemplate: [''],
      idMailbox: [''],
      idAttachmentMode: [''],
    });

    for (const controlName of Object.keys(this.formGroupFo.controls)) {
      this.initialFormControlNames.push(controlName);
    }
  }

  /**
   * @description A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
   */
  public ngOnInit(): void {
    this.spinner.show();
    /** spinner ends after 5 seconds */
    this.coreFacade.getCompanies(() => {
      this.coreFacade.getTypeClients(() => {
        this.spinner.hide();
      });
    });

    this.updateModelData();
  }

  /**
   * Método encargado de actualizar los modelos de datos
   */
  public updateModelData() {
    this.formGroupFo.valueChanges.subscribe(() => {
      this.sendData();
    });
  }

  /**
   * Método encargado de controlar el flag si el cliente es anónimo o no
   */
  public flagAnonymous() {
    this.isAnonymous = this.formGroupFo.controls.changeAnonymous.value;
    this.formGroupFo.reset({ changeAnonymous: this.isAnonymous, country: 'Colombia' });
    this.updateModelData();
    // Establece los campos obligatorios según si el cliente es o no anónimo
    if (this.isAnonymous) {
      this.formGroupFo.get('typeClient')?.clearValidators();
      this.formGroupFo.get('typeDocument')?.clearValidators();
      this.formGroupFo.get('document')?.clearValidators();
      this.formGroupFo.get('name')?.clearValidators();
      this.formGroupFo.get('meansResponse')?.clearValidators();
      this.formGroupFo.get('product')?.clearValidators();
      this.formGroupFo.get('typeOfRequest')?.clearValidators();
      this.formGroupFo.get('detailOfRequest')?.clearValidators();
    } else {
      this.formGroupFo.get('typeClient')?.setValidators([Validators.required]);
      this.formGroupFo.get('typeDocument')?.setValidators([Validators.required]);
      this.formGroupFo.get('document')?.setValidators([Validators.required]);
      this.formGroupFo.get('name')?.setValidators([Validators.required]);
      this.formGroupFo.get('meansResponse')?.setValidators([Validators.required]);
      this.formGroupFo.get('product')?.setValidators([Validators.required]);
      this.formGroupFo.get('typeOfRequest')?.setValidators([Validators.required]);
      this.formGroupFo.get('detailOfRequest')?.setValidators([Validators.required]);
    }
    // Validar y actualiza los campos obligatorios
    this.formGroupFo.updateValueAndValidity();
    this.formGroupFo.get('typeClient')?.updateValueAndValidity();
    this.formGroupFo.get('typeDocument')?.updateValueAndValidity();
    this.formGroupFo.get('document')?.updateValueAndValidity();
    this.formGroupFo.get('name')?.updateValueAndValidity();
    this.formGroupFo.get('meansResponse')?.updateValueAndValidity();
    this.formGroupFo.get('product')?.updateValueAndValidity();
    this.formGroupFo.get('typeOfRequest')?.updateValueAndValidity();
    this.formGroupFo.get('detailOfRequest')?.updateValueAndValidity();
  }

  /**
   * Método encargado de filtrar las ciudades según el departamento seleccionado
   * @param event Valor seleccionado
   */
  public onDepartmentChange(event: MatSelectChange) {
    this.cities$ = this.coreFacade
      .citiesAll()
      .pipe(map((items: CityDto[]) => items.filter((item: CityDto) => item.idDepartment === Number(event))));
  }

  /**
   * @description Método llamado por el evento keyup, para contar el numero de caracteres
   */
  public countLetters() {
    // Actualizar el nombre del metodo
    const form = this.formGroupFo.value;
    this.valueDescription = form.textRequest.length;
  }

  /**
   * Método llamado por el evento keypress permite solo números
   * @param event Valores del campo
   * @returns Devuelve un valor booleano
   */
  public numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  /**
   * Método encargado de enseñar la descripción del tipo de solicitud
   * @param event Valor seleccionado
   */
  public flagDescriptionTypeRequest(event: MatSelectChange) {
    this.isTypeRequest = event.value === 'Queja anónima';
  }

  /**
   * Método llamado por el evento change de la lista de Tipos de cliente
   * @param event Valor seleccionado
   */
  public onTypeClientChange(event: MatSelectChange) {
    this.removeNotInitialContros();

    const selectedCompany = this.companyId;

    // Elimina controles si no es tercero
    if (Number(event) !== 3) {
      this.removeNewControl();
    } else {
      // Agrega controles si es tercero
      this.addNewControl();
    }
    // Actualiza los datos según la nueva selección de tipo de cliente y compañía
    this.coreFacade.getTypologies(selectedCompany, +event);
  }

  /**
   * Método llamado luego de que una propiedad enlazada cambia
   * @param changes Cambios en el control
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (!changes.jsonFormData.firstChange) {
      this.addControlsToForm(this.jsonFormData.controls);
    }
  }

  /**
   * @description Método que agrega los controles al Formulario
   * @param controls arreglo de controles que se agregarán
   */
  public addControlsToForm(controls: JsonFormControls[]) {
    if (controls) {
      for (const control of controls) {
        this.addControl(control);
      }
    }
  }

  /**
   * Método que se llamará para remover un control del Form
   * @param controlName Nombre del control
   */
  private removeControl(controlName: string) {
    if (this.formGroupFo.contains(controlName)) {
      this.formGroupFo.removeControl(controlName);
    }
  }

  /**
   * @description Método que elimina los controles no iniciales
   */
  private removeNotInitialContros() {
    for (const controlName of Object.keys(this.formGroupFo.controls)) {
      if (!this.initialFormControlNames.includes(controlName)) {
        this.removeControl(controlName);
        this.jsonFormData.controls = new Array<JsonFormControls>();
      }
    }
  }

  /**
   * @description Método llamado para remover validaciones
   */
  public removeNewControl() {
    this.formGroupFo = this.formBuilderFo.group({
      ...this.formGroupFo.controls,
      name: [''],
    });
  }

  /**
   * @description Método llamado para agregar Control al formulario
   * @param control Control que se agregará
   */
  private addControl(control: JsonFormControls) {
    this.formGroupFo.addControl(control.name, this.formBuilderFo.control(control.value, getValidators(control)));
  }

  /**
   * @description Método llamado para agregar Control al formulario
   * @param control Control que se agregará
   */
  public onAddControl(addControlEvent: JsonFormControls) {
    this.addControl(addControlEvent);
    addControlEvent.visible = true;
  }

  /**
   * @description Método llamado para agregar validaciones
   */
  public addNewControl() {
    this.formGroupFo = this.formBuilderFo.group({
      ...this.formGroupFo.controls,
      name: ['', Validators.required],
    });
  }

  /**
   * Método encargado de validar la cédula del cliente
   */
  public validateDocument() {
    this.spinner.show();
    this.coreFacade.validateClient(this.companyId, this.formGroupFo.get('document')?.value, (data) => {
      const validateEm = JSON.stringify(data);
      const validateEmJson: DataDecryptMe = JSON.parse(validateEm);
      const dataDecrypt = this.encryptService.toDecrypt(validateEmJson.data);
      const dataDeserealized = JSON.parse(dataDecrypt) as ValidateClient;
      this.isClient = dataDeserealized.isClient;
      this.spinner.hide();
    });
  }

  /**
   * Método encargado de validar el tipo de respuesta
   */
  public validateMeansResponse() {
    this.selectedMeansResponse = this.formGroupFo.value.meansResponse;
    // Establece los campos obligatorios según el tipo de respuesta
    if (this.selectedMeansResponse) {
      this.formGroupFo
        .get('email')
        ?.setValidators([
          Validators.required,
          Validators.email,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.(com|org|net|edu|gov|mil|info|io|co|me|biz|xyz|us|uk|es|fr|de|jp|cn|br|au|ca|COM|ORG|NET|EDU|GOV|MIL|INFO|IO|CO|ME|BIZ|XYZ|US|UK|ES|FR|DE|JP|CN|BR|AU|CA)$',
          ),
        ]);
      this.formGroupFo.get('department')?.clearValidators();
      this.formGroupFo.get('city')?.clearValidators();
      this.formGroupFo.get('neighborhood')?.clearValidators();
      this.formGroupFo.get('address')?.clearValidators();
    } else {
      this.formGroupFo.get('email')?.clearValidators();
      this.formGroupFo.get('department')?.setValidators([Validators.required]);
      this.formGroupFo.get('city')?.setValidators([Validators.required]);
      this.formGroupFo.get('neighborhood')?.setValidators([Validators.required]);
      this.formGroupFo.get('address')?.setValidators([Validators.required]);
    }
    // Validar y actualiza los campos obligatorios
    this.formGroupFo.get('email')?.updateValueAndValidity();
    this.formGroupFo.get('department')?.updateValueAndValidity();
    this.formGroupFo.get('city')?.updateValueAndValidity();
    this.formGroupFo.get('neighborhood')?.updateValueAndValidity();
    this.formGroupFo.get('address')?.updateValueAndValidity();
  }

  /**
   * Método encargado de validar si el tipo de solicitud es una sugerencia
   */
  public validateSuggestion() {
    this.formGroupFo
      .get('typeOfRequest')
      ?.valueChanges.pipe(take(1))
      .subscribe((value) => {
        this.isSuggestion = value === 'Sugerencia/Propuesta' ? true : false;
        // Establece los campos obligatorios según si el tipo de solicitud es o no una sugerencia
        if (this.isSuggestion) {
          this.formGroupFo.get('typeClient')?.clearValidators();
          this.formGroupFo.get('typeDocument')?.clearValidators();
          this.formGroupFo.get('document')?.clearValidators();
          this.formGroupFo.get('name')?.clearValidators();
          this.formGroupFo.get('meansResponse')?.clearValidators();
        } else {
          this.formGroupFo.get('typeClient')?.setValidators([Validators.required]);
          this.formGroupFo.get('typeDocument')?.setValidators([Validators.required]);
          this.formGroupFo.get('document')?.setValidators([Validators.required]);
          this.formGroupFo.get('name')?.setValidators([Validators.required]);
          this.formGroupFo.get('meansResponse')?.setValidators([Validators.required]);
        }
        // Validar y actualiza los campos obligatorios
        this.formGroupFo.get('typeClient')?.updateValueAndValidity();
        this.formGroupFo.get('typeDocument')?.updateValueAndValidity();
        this.formGroupFo.get('document')?.updateValueAndValidity();
        this.formGroupFo.get('name')?.updateValueAndValidity();
        this.formGroupFo.get('meansResponse')?.updateValueAndValidity();
        this.sendData();
      });
  }

  /**
   * @description Método para actualizar los campos Metadata en el formulario
   * @param metadata Argumento con la información para establecer o remover los campos
   */
  private setMetadataFields(metadata: JsonFormMetadata) {
    const idTemplateControl = this.formGroupFo.get('idTemplate');
    idTemplateControl?.setValue(metadata.idTemplate);
    const idMailboxControl = this.formGroupFo.get('idMailbox');
    idMailboxControl?.setValue(metadata.idMailbox);
    const idAttachmentModeControl = this.formGroupFo.get('idAttachmentMode');
    idAttachmentModeControl?.setValue(metadata.idAttachmentMode);
    this.updateModelData();
  }

  /**
   * Método encargado de enviar la información solicitada para enviar el formulario
   */
  public sendData() {
    // Se inicia el modelo vacio
    this.dataModel = {};
    // Envío de formulario si es anónimo
    if (this.isAnonymous) {
      this.dataModel = {
        IsAnonymus: true,
        Company: this.companies.find((x) => x.id === this.companyId)?.name,
        TypeClient: 'Anonimo',
        TypeRequest: this.formGroupFo.get('typeRequest')?.value,
        MessageClient: this.formGroupFo.get('textRequest')?.value,
        IdTemplate: 4,
        IdAttachmentMode: 2,
        IdCLient: this.companyId === 1 ? environment.occidenteIdClient : environment.fiduoccidenteIdClient,
        IdCamapaign: this.companyId === 1 ? environment.occidenteIdCampaign : environment.fiduoccidenteIdCampaign,
      };
    }
    // Envío de formulario si es una sugerencia
    if (!this.isAnonymous && this.isSuggestion) {
      this.dataModel = {
        Company: this.companies.find((x) => x.id === this.companyId)?.name,
        TypeClient: this.typeClients.find((x) => x.id === this.formGroupFo.value.typeClient)?.name,
        Product: this.formGroupFo.get('product')?.value,
        TypeRequest: this.formGroupFo.get('typeOfRequest')?.value,
        MessageClient: this.formGroupFo.get('textRequest')?.value,
        IdTemplate: 5,
        IdAttachmentMode: 2,
        IdCLient: this.companyId === 1 ? environment.occidenteIdClient : environment.fiduoccidenteIdClient,
        IdCamapaign: this.companyId === 1 ? environment.occidenteIdCampaign : environment.fiduoccidenteIdCampaign,
      };
    }
    // Envío de formulario si no es anónimo
    if (!this.isAnonymous && !this.isSuggestion) {
      this.dataModel = {
        TypeClient: this.typeClients.find((x) => x.id === this.formGroupFo.value.typeClient)?.name,
        Product: this.formGroupFo.get('product')?.value,
        TypeRequest: this.formGroupFo.get('typeOfRequest')?.value,
        DetailRequest: this.formGroupFo.get('detailOfRequest')?.value,
        TypeDocument: this.formGroupFo.get('typeDocument')?.value,
        NumberDocument: this.formGroupFo.get('document')?.value,
        Name: this.formGroupFo.get('name')?.value,
        ResponseMedium:
          this.formGroupFo.get('meansResponse')?.value === true
            ? 'Correo electrónico'
            : 'Dirección física de correspondencia',
        Email: this.formGroupFo.get('email')?.value,
        Country: this.formGroupFo.get('country')?.value,
        Department: this.departments.find((x) => x.id === this.formGroupFo.value.department)?.name,
        City: this.formGroupFo.get('city')?.value,
        Neighborhood: this.formGroupFo.get('neighborhood')?.value,
        Address: this.formGroupFo.get('address')?.value,
        ContactNumber: Number(this.formGroupFo.get('numberContact')?.value),
        MessageClient: this.formGroupFo.get('textRequest')?.value,
        IdMailBox: Number(this.formGroupFo.get('idMailbox')?.value),
        IdTemplate: Number(this.formGroupFo.get('idTemplate')?.value),
        IdAttachmentMode: Number(this.formGroupFo.get('idAttachmentMode')?.value),
        Company: this.companies.find((x) => x.id === this.companyId)?.name,
        IsClient: true,
        IdCLient: environment.fiduoccidenteIdClient,
        IdCamapaign: environment.fiduoccidenteIdCampaign,
      };
    }
  }
  /**
   * Metodo ngOnDestroy
   */
  public ngOnDestroy() {
    if (this.jsonFormData) {
      this.jsonFormData.controls = new Array<JsonFormControls>();
    }
    this.coreFacade.ngOnDestroy();
  }
}
