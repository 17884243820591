import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';

import { TypeAttach } from '../../../core/models/type-attach';
import { DataTransferService } from '../../../services/data-transfer.service';
import { getValidators } from '../../functions';
import { JsonFormControls } from '../../models/json-form-data';

/**
 * SelectsComponent permite crear/visualizar y agregar componentes de un formulario
 */
@Component({
  selector: 'app-selects',
  styleUrls: ['./selects.component.scss'],
  templateUrl: './selects.component.html',
})
export class SelectsComponent implements OnInit {
  /**
   * Creador de Formulario
   */
  @Input()
  public formBuilder!: FormBuilder;

  /**
   * Contiene los controles del formulario
   */
  public form!: FormGroup;

  /**
   * rootFormGroup directiva para asociar al Fromulario externo.
   */
  private readonly rootFormGroup: FormGroupDirective;

  /**
   * Servicio encargado de transferir datos entre componentes
   */
  public dataTransferService: DataTransferService;

  /**
   * Padre del Componente
   */
  @Input() public parentControl!: JsonFormControls;

  /**
   * Hermanos del Componente (para remover de la vista cuando cambie el control)
   */
  @Input() public siblingsControls!: JsonFormControls[] | undefined;

  /**
   * Control asociado al componente
   */
  @Input() public control!: JsonFormControls;

  /**
   * Encargado de guardar los childrenRequired
   */
  public filteredChildren: JsonFormControls[] = [];

  /**
   * Método para hacer visible externamente desde la plantilla
   */
  @Input()
  /**
   * @description Método para hacer visible externamente desde la plantilla
   *
   * @param value Valor booleano a establecer
   */
  public set visible(value: boolean) {
    this.control.visible = value;
  }

  /**
   * Emisor de Eventos indicando se ha (o se debe crear o agregar a un formulario) Control
   */
  @Output() public controlAdded = new EventEmitter<JsonFormControls>();

  /**
   * @description Crea una nueva instancia de SelectsComponent
   *
   * @param rootFormGroup directiva para asociar al Fromulario externo.
   */
  public constructor(rootFormGroup: FormGroupDirective, dataTransferService: DataTransferService) {
    this.rootFormGroup = rootFormGroup;
    this.dataTransferService = dataTransferService;
  }

  /**
   * Método llamado luego de que han inicializados todas las propiedades
   */
  public ngOnInit(): void {
    this.form = this.rootFormGroup.form;
    this.removeSiblingsControls(this.siblingsControls);
    // Filtra los hijos requeridos
    if (this.control?.children) {
      this.filteredChildren = this.control.children.filter((child) => child.validators?.required === true);
    }
  }

  /**
   * Método que se llamará cuando se presente un evento de cambio en el Select
   * @param event evento
   */
  public onSelectChange(event: string) {
    this.dataTransferService.showRequest = false;
    this.dataTransferService.behaviorSubjectTypeAttach.next(TypeAttach.Disabled);
    this.removeSiblingsControls(this.siblingsControls);
    if (this.control.children) {
      this.recursiveConcealer(this.control.children);
      const childFound = this.control.children.find((child) => child.value === event);
      if (childFound) {
        if (childFound.children) {
          this.addControl(childFound);
        } else {
          childFound.visible = true;
        }
        if (childFound.metadata) {
          this.dataTransferService.showRequest = true;
          this.dataTransferService.behaviorSubjectTypeAttach.next(childFound.metadata.idAttachmentMode);
          this.dataTransferService.subjectMetadata.next(childFound.metadata);
        }
      }
    }
  }

  /**
   * Método que para remover los otros controles hermanos del formulario
   * @param siblingsControls todos los controles hermanos
   */
  private removeSiblingsControls(siblingsControls: JsonFormControls[] | undefined) {
    if (siblingsControls) {
      for (const sibling of siblingsControls) {
        if (sibling.name !== this.control.name) {
          this.recursiveConcealer([sibling]);
        }
      }
    }
  }

  /**
   * Método que se llamará recursivamente para ocultar/remover componentes hijos
   * @param children hijos
   */
  private recursiveConcealer(children?: JsonFormControls[]) {
    if (children) {
      for (const child of children) {
        child.visible = false;
        this.removeControl(child);
        this.recursiveConcealer(child.children);
      }
    }
  }

  /**
   * Método que se llamará para remover un control del Form
   * @param control control a remover
   */
  private removeControl(control: JsonFormControls) {
    if (this.form.contains(control.name)) {
      this.form.removeControl(control.name);
    }
  }

  /**
   * Método que se llamará para agregar un control al Form y FormBuilder
   * @param control control a agregar
   */
  private addControl(control: JsonFormControls) {
    control.visible = true;
    this.form.addControl(control.name, this.formBuilder.control(control.name, getValidators(control)));
  }
}
