import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { CaptchaDto, InteractionDto, ReCaptchaDto } from '@bdo/interaction';
import { CompanyDto, TypeClientDto } from '@bdo/typology';
import { environment } from 'apps/form-contact/src/environments/environment';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { DialogPopupComponent } from '../../../core/components/dialog-popup/dialog-popup.component';
import { CoreFacade } from '../../../core/core.facade';
import { TypeAttach } from '../../../core/models/type-attach';
import { DataTransferService } from '../../../services/data-transfer.service';
import { EncryptService } from '../../../shared/services/encyrpt/encrypt.service';
import { TealiumUtagService } from '../../../tealium/utag.service';
import { getValidators } from '../../functions';
import { DataDecryptMe } from '../../models/data-decrypt-me';
import { DataModel } from '../../models/data-model';
import { FileData } from '../../models/file-data';
import { Interaction } from '../../models/interaction-model';
import { JsonFormControls, JsonFormData, JsonFormMetadata } from '../../models/json-form-data';
import { ValidateClient } from '../../models/validate-client';

/**
 * JsonFormComponent permite crear formularios agregando controles a partir de un JSON
 */
@Component({
  selector: 'app-json-form',
  styleUrls: ['./json-form.component.scss'],
  templateUrl: './json-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JsonFormComponent implements OnChanges, OnInit, OnDestroy {
  /**
   * Servicio encargado de interactuar con el servicio de encriptación
   */
  private readonly encryptService: EncryptService;
  /**
   * Archivos selecionados
   */
  public selectedFile: File[] = [];
  /**
   * Observable destroy
   */
  public destroy$: Subject<void> = new Subject<void>();
  /**
   * Creador de Formulario para ser expuesto
   */
  public formBuilder: FormBuilder;
  /**
   * Contiene los controles del formulario
   */
  public formGroup: FormGroup;
  /**
   * Diálogo Popup con resultado del envío
   */
  public dialogPopup: MatDialog;
  /**
   * Contiene los controles del formulario
   */
  private readonly initialFormControlNames: string[] = [];
  /**
   * Mínimo valor para documento
   */
  public readonly minValueDocument = 99999;
  /**
   * Mínimo número de caracteres por solicitud
   */
  public readonly minNumberCharPerRequest = 10;
  /**
   * Mínimo número de caracteres por solicitud
   */
  public readonly maxNumberCharPerRequest = 750;
  /**
   * Número de caracteres en la descripción
   */
  public valor = 0;
  /**
   * JsonFormData asociado al componente
   */
  @Input() public jsonFormData!: JsonFormData;
  /**
   * Observable con Company
   */
  public readonly companies$: Observable<CompanyDto[]>;
  /**
   * Arreglo con Company
   */
  private companies: CompanyDto[] = [];
  /**
   * Observable con TypeClient
   */
  public readonly typeClients$: Observable<TypeClientDto[]>;
  /**
   * Arreglo con TypeClient
   */
  private typeClients: TypeClientDto[] = [];
  /**
   * Observable con ReCaptcha
   */
  private readonly reCaptcha$: Observable<ReCaptchaDto>;
  /**
   * Facade Core
   */
  public coreFacade: CoreFacade;
  /**
   * Servicio encargado de transferir datos entre componentes
   */
  public dataTransferService: DataTransferService;
  /**
   * Nombre de Campo para Archivos
   */
  public readonly filesFieldname = 'files';
  /**
   * visibleInputFile: Variable de control para mostrar Input de tipo File
   */
  public visibleInputFile = true;
  /**
   * visibleInputNoClient: Variable de control para mostrar Input de tipo no cliente
   */
  public visibleInputNoClient = false;
  /**
   * visibleInputNoClientFidu: Variable de control para mostrar Input de tipo no cliente
   */
  public visibleInputNoClientFidu = false;
  /**
   * visibleInputNoClientOcci: Variable de control para mostrar Input de tipo no cliente
   */
  public visibleInputNoClientOcci = false;
  /**
   * visibleInputNoClientOcci: Variable de control para mostrar Input de tipo no cliente
   */
  public visibleInputTypeClient = false;
  /**
   * tryRecaptcha: Intento del Recatcha
   */
  public tryRecaptcha = false;
  /**
   * errorTextRequestSubmit: Intento de envío con error
   */
  public errorTextRequestSubmit = false;
  /**
   * BehaviorSubject para mostrar archivo subido
   */
  private readonly BehaviorSubjectMessageSelectFile = new BehaviorSubject<string>('Seleccione su archivo');
  /**
   * messageSelectFile$ texto para mostrar archivo subido
   */
  public readonly messageSelectFile$ = this.BehaviorSubjectMessageSelectFile.asObservable();
  /**
   * messageButton texto para botón
   */
  public messageButton = 'Subir';
  /**
   * Lista de Archivos seleccionados
   */
  public selectedFiles?: FileList;
  /**
   * Arreglo con la información de los archivos.
   */
  public files: File[] = [];
  /**
   * Spinner Service
   */
  public readonly spinner: NgxSpinnerService;
  /**
   * Tealium
   */
  public readonly tealium: TealiumUtagService;
  /**
   * Bandera para validar estado de archivos
   */
  public validateFiles = false;
  /**
   * Mensaje de alerta de archivos
   */
  public messageValidateFiles = '';
  /**
   * Flag para visualizar el mensaje segun la compañia
   */
  public visibleMessage = false;
  /**
   * Flag para escoger mensaje segun la compañia
   */
  public message = false;

  /**
   * Método para verificar si se muestra Input de tipo File o no.
   */
  public get showInputFile(): boolean {
    return this.visibleInputFile;
  }
  /**
   * Método para exponer que el Recaptcha sea válido
   */
  public validRecaptcha$ = new BehaviorSubject(false);
  /**
   * @description Crea una nueva instancia de JsonFormComponent
   *
   * @param formBuilder Parámetro-Propiedad de tipo FormBuilder (uso interno)
   *
   * @param coreFacade Injecta la instancia de CoreFacade
   *
   * @param dataTransferService para transferir datos entre componentes
   */
  public constructor(
    formBuilder: FormBuilder,
    dialogPopup: MatDialog,
    coreFacade: CoreFacade,
    dataTransferService: DataTransferService,
    spinner: NgxSpinnerService,
    tealium: TealiumUtagService,
    encryptService: EncryptService,
  ) {
    this.formBuilder = formBuilder;
    this.formGroup = this.formBuilder.group({});
    this.dialogPopup = dialogPopup;
    this.coreFacade = coreFacade;
    this.spinner = spinner;
    this.tealium = tealium;
    this.encryptService = encryptService;
    this.dataTransferService = dataTransferService;
    this.formGroup = this.buildFormGroup();
    for (const controlName of Object.keys(this.formGroup.controls)) {
      this.initialFormControlNames.push(controlName);
    }
    this.companies$ = this.coreFacade.companies$();
    this.typeClients$ = this.coreFacade.typeClients$();
    this.reCaptcha$ = this.coreFacade.reCaptcha$();

    dataTransferService.behaviorSubjectTypeAttach.subscribe((response) => this.updateFilesValidators(response));
    dataTransferService.subjectMetadata.subscribe((response) => this.setMetadataFields(response));
    this.companies$.subscribe((response) => (this.companies = response));
    this.typeClients$.subscribe((response) => (this.typeClients = response));
    this.reCaptcha$.subscribe((response) => this.doRecaptchaReceived(response));
    this.coreFacade.radicado$().subscribe((response) => {
      dataTransferService.radicado = response;
      this.dialogPopup.open(DialogPopupComponent, {
        disableClose: true,
      });
    });
    this.tealium.setConfig({
      account: environment.tealiumAccount,
      profile: environment.tealiumProfile,
      environment: environment.tealiumEnviroment,
    });
  }
  /**
   * @description A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
   */
  public ngOnInit(): void {
    this.spinner.show();
    /** spinner ends after 5 seconds */
    this.coreFacade.getCompanies(() => {
      this.coreFacade.getTypeClients(() => {
        this.spinner.hide();
      });
    });
  }
  /**
   * Metodo ngOnDestroy
   */
  public ngOnDestroy() {
    this.coreFacade.ngOnDestroy();
  }
  /**
   * @description Método llamado luego de que una propiedad enlazada cambia
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (!changes.jsonFormData.firstChange) {
      this.addControlsToForm(this.jsonFormData.controls);
    }
  }
  /**
   * @description Método encargado de crear el formulario
   */
  private buildFormGroup() {
    let internalFormGroup;
    if (this.visibleInputNoClient) {
      internalFormGroup = this.formBuilder.group({
        document: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(12)]],
        company: ['', [Validators.required]],
        typeClient: ['', [Validators.required]],
        textRequest: [
          '',
          [Validators.required, Validators.minLength(this.minNumberCharPerRequest), Validators.maxLength(750)],
        ],
        name: [''],
        email: [
          '',
          [
            Validators.required,
            Validators.email,
            Validators.pattern(
              '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.(com|org|net|edu|gov|mil|info|io|co|me|biz|xyz|us|uk|es|fr|de|jp|cn|br|au|ca|COM|ORG|NET|EDU|GOV|MIL|INFO|IO|CO|ME|BIZ|XYZ|US|UK|ES|FR|DE|JP|CN|BR|AU|CA)$',
            ),
          ],
        ],
        files: [''],
        idTemplate: [''],
        idMailbox: [''],
        idAttachmentMode: [''],
        recaptchaReactive: [null, Validators.required],
      });
    } else {
      internalFormGroup = this.formBuilder.group({
        document: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(12)]],
        company: ['', [Validators.required]],
        typeClient: ['', [Validators.required]],
        textRequest: [
          '',
          [Validators.required, Validators.minLength(this.minNumberCharPerRequest), Validators.maxLength(750)],
        ],
        files: [''],
        name: [''],
        email: [
          '',
          [
            Validators.required,
            Validators.email,
            Validators.pattern(
              '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.(com|org|net|edu|gov|mil|info|io|co|me|biz|xyz|us|uk|es|fr|de|jp|cn|br|au|ca|COM|ORG|NET|EDU|GOV|MIL|INFO|IO|CO|ME|BIZ|XYZ|US|UK|ES|FR|DE|JP|CN|BR|AU|CA)$',
            ),
          ],
        ],
        idTemplate: [''],
        idMailbox: [''],
        idAttachmentMode: [''],
        recaptchaReactive: [null, Validators.required],
      });
    }

    return internalFormGroup;
  }

  /**
   * @description Método para actualizar los campos Metadata en el formulario
   *
   * @param metadata Argumento con la información para establecer o remover los campos
   */
  private setMetadataFields(metadata: JsonFormMetadata) {
    const idTemplateControl = this.formGroup.get('idTemplate');
    idTemplateControl?.setValue(metadata.idTemplate);
    const idMailboxControl = this.formGroup.get('idMailbox');
    idMailboxControl?.setValue(metadata.idMailbox);
    const idAttachmentModeControl = this.formGroup.get('idAttachmentMode');
    idAttachmentModeControl?.setValue(metadata.idAttachmentMode);
  }

  /**
   * @description Método para actualizar los validators para el campo files del formulario
   *
   * @param typeAttach Argumento con la información para establecer o remover las validaciones
   */
  private updateFilesValidators(typeAttach: TypeAttach) {
    const filesControl = this.formGroup.get(this.filesFieldname);
    filesControl?.clearAsyncValidators();
    switch (typeAttach) {
      case TypeAttach.Required: {
        this.visibleInputFile = true;
        filesControl?.setValidators([Validators.required, Validators.minLength(5)]);
        break;
      }
      case TypeAttach.Optional: {
        this.visibleInputFile = true;
        break;
      }
      case TypeAttach.Disabled: {
        this.visibleInputFile = false;
        filesControl?.setValidators([Validators.maxLength(1)]);
        break;
      }
      default: {
        this.visibleInputFile = false;
        filesControl?.clearAsyncValidators();
      }
    }
    filesControl?.updateValueAndValidity();
  }

  /**
   * @description Método que agrega los controles al Formulario
   *
   * @param controls arreglo de controles que se agregarán
   */
  public addControlsToForm(controls: JsonFormControls[]) {
    if (controls) {
      for (const control of controls) {
        this.addControl(control);
      }
    }
  }

  /**
   * @description Método llamado para agregar validaciones a los campos name y email si es tercero
   */
  public addNewControl() {
    this.formGroup = this.formBuilder.group({
      ...this.formGroup.controls,
      name: ['', Validators.required],
    });
  }
  /**
   * @description Método llamado para remover validaciones a los campos name y email si no es tercero
   */
  public removeNewControl() {
    this.formGroup = this.formBuilder.group({
      ...this.formGroup.controls,
      name: [''],
    });
  }

  /**
   * @description Método llamado para agregar Control al formulario
   * @param control Control que se agregará
   */
  private addControl(control: JsonFormControls) {
    this.formGroup.addControl(control.name, this.formBuilder.control(control.value, getValidators(control)));
  }

  /**
   * @description Método llamado para agregar Control al formulario
   * @param control Control que se agregará
   */
  public onAddControl(addControlEvent: JsonFormControls) {
    this.addControl(addControlEvent);
    addControlEvent.visible = true;
  }

  /**
   * @description Método que elimina los controles no iniciales
   */
  private removeNotInitialContros() {
    for (const controlName of Object.keys(this.formGroup.controls)) {
      if (!this.initialFormControlNames.includes(controlName)) {
        this.removeControl(controlName);
        this.jsonFormData.controls = new Array<JsonFormControls>();
      }
    }
  }

  /**
   * Método que se llamará para remover un control del Form
   */
  private removeControl(controlName: string) {
    if (this.formGroup.contains(controlName)) {
      this.formGroup.removeControl(controlName);
    }
  }

  /**
   * @description Método llamado por el evento keypress permite solo números
   */
  public numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    this.formGroup.controls.company.setValue('');

    return true;
  }
  /**
   * @description Método llamado por el evento keyup, para contar el numero de caracteres
   */
  public count() {
    const form = this.formGroup.value;
    this.valor = form.textRequest.length;
  }
  /**
   * @description Método llamado por el evento change de la lista de Compañías
   */
  public onCompanyChange(event: MatSelectChange) {
    const form = this.formGroup.value;
    if (Number(event) !== 0 && form.document !== '') {
      // Restablecer valor del tipo de cliente
      this.removeNewControl();
      this.formGroup.patchValue({ typeClient: '' });
      this.dataTransferService.showRequest = false;
      this.tryRecaptcha = false;
      this.errorTextRequestSubmit = false;
      this.removeNotInitialContros();
      this.spinner.show();
      this.message = false;

      // Verificar si el tipo de cliente es "tercero" y se agregaron controles adicionales
      if (Number(event) !== 3 && this.visibleInputNoClient) {
        this.visibleInputNoClient = false;
        this.removeNewControl(); // Función para eliminar los controles adicionales
      }

      // Quita tercero para occidente
      if (Number(event) === 1) {
        this.message = true;
        this.typeClients$
          .pipe(
            map((x) => {
              const datos = x.filter((y) => y.id !== 3);
              this.coreFacade.setTypeClients(datos);
            }),
            takeUntil(this.coreFacade.destroy$),
          )
          .subscribe();
      } else {
        this.coreFacade.getTypeClients(() => {
          this.message = false;
          this.typeClients$
            .pipe(takeUntil(this.coreFacade.destroy$))
            .subscribe((response) => (this.typeClients = response));
        });
      }

      // Validación de cliente
      this.coreFacade.validateClient(+event, form.document, (data) => {
        const validateEm = JSON.stringify(data);
        const validateEmJson: DataDecryptMe = JSON.parse(validateEm);
        const dataDecrypt = this.encryptService.toDecrypt(validateEmJson.data);
        const dataDeserealized = JSON.parse(dataDecrypt) as ValidateClient;
        if (dataDeserealized.isClient) {
          this.coreFacade.getTypologies(+event, form.typeClient);
          this.visibleInputNoClientOcci = false;
          this.visibleInputNoClientFidu = false;
          this.visibleInputTypeClient = true;
        } else {
          if (Number(event) === 1) {
            this.visibleInputNoClientOcci = true;
            this.visibleInputNoClientFidu = false;
            this.visibleInputTypeClient = false;
            this.visibleMessage = false;
          } else {
            this.visibleInputNoClientFidu = true;
            this.visibleInputNoClientOcci = false;
            this.visibleInputTypeClient = false;
            this.visibleMessage = false;
          }
        }

        this.spinner.hide();
      });
    } else {
      this.errorTextRequestSubmit = false;
    }
  }
  /**
   * @description Método llamado por el evento change de la lista de Tipos de cliente
   */
  public onTypeClientChange(event: MatSelectChange) {
    this.visibleMessage = true;
    this.tryRecaptcha = false;
    this.errorTextRequestSubmit = false;
    this.dataTransferService.showRequest = false;
    this.removeNotInitialContros();

    const selectedCompany = this.formGroup.value.company;

    // Elimina controles si no es tercero
    if (Number(event) !== 3) {
      this.visibleInputNoClient = false;
      this.removeNewControl();
    } else {
      // Agrega controles si es tercero
      this.visibleInputNoClient = true;
      this.addNewControl();
    }
    // Actualiza los datos según la nueva selección de tipo de cliente y compañía
    this.coreFacade.getTypologies(selectedCompany, +event);
  }

  /**
   * Metodo para el cargue de archivos
   */
  public onFileChange(event: any) {
    this.validateFiles = false;
    this.selectedFiles = event.target.files as FileList;
    const filesControl = this.formGroup.get(this.filesFieldname);

    if (this.selectedFiles && this.selectedFiles.length > 0) {
      let totalSize = this.calculateTotalSize(this.files);

      // Convertir FileList a un array para poder iterar
      const filesArray = Array.from(this.selectedFiles);

      for (const file of filesArray) {
        if (this.isValidFile(file)) {
          if (totalSize + file.size <= 25165824) {
            // Verificar si el tamaño total no excede 25 MB
            totalSize += file.size;
            this.readFileContent(file, filesControl);
          } else {
            this.validateFiles = true;
            this.messageValidateFiles = 'El tamaño total a cargar es mayor a 24 MB';
            break; // Salir del bucle si se excede el tamaño máximo
          }
        } else {
          this.validateFiles = true;
          this.messageValidateFiles = 'Los formatos de archivo deben ser JPG, PDF, TIFF, JPEG';
          break; // Salir del bucle si se encuentra un archivo no válido
        }
      }
    }
  }

  /**
   * Metodo para calcular si el peso total de los archivos es valido
   */
  public calculateTotalSize(files: FileData[]): number {
    return files.reduce((acc, file) => acc + file.size, 0);
  }
  /**
   * Metodo encargado de validar el formato del archivo
   */
  public isValidFile(file: File): boolean {
    const allowedFormats = ['pdf', 'jpg', 'jpeg', 'tiff'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (fileExtension) {
      return allowedFormats.includes(fileExtension);
    }

    return false; // Devolver falso si no se puede determinar la extensión del archivo
  }
  /**
   * Función para eliminar o modificar caracteres especiales del nombre del archivo
   * @param fileName Nombre original del archivo
   * @returns Nombre del archivo sanitizado
   */
  public sanitizeFileName(fileName: string): string {
    // Reemplazar caracteres especiales por guiones bajos o eliminar espacios
    return fileName
      .replace(/[^a-zA-Z0-9.\-_]/g, '_')
      .replace(/\s+/g, '_')
      .replace(/_+/g, '_');
  }

  /**
   * Función para leer el contenido del archivo seleccionado
   */
  public readFileContent(file: File, filesControl: AbstractControl | null) {
    // Verificar si el archivo con el mismo nombre ya está en la lista de archivos
    const existingFile = this.files.find((f) => f.name.toLowerCase() === file.name.toLowerCase());

    if (existingFile) {
      // Manejar el caso de archivo con el mismo nombre
      this.validateFiles = true;
      this.messageValidateFiles = 'Ya existe un archivo con el mismo nombre en la lista';
    } else {
      const reader = new FileReader();

      reader.onload = () => {
        // Convertir la extensión del archivo a minúsculas antes de añadirlo a la lista
        const fileNameParts = file.name.split('.');
        // Convertimos la extensión en minúsculas
        const extension = fileNameParts.pop()?.toLowerCase();
        // Reconstruimos el nombre base y lo sanitizamos
        const baseName = this.sanitizeFileName(fileNameParts.join('.'));
        // Unimos las partes
        const sanitizedFileName = `${baseName}.${extension}`;

        // Creamos un nuevo objeto File con el mismo contenido
        const sanitizedFile = new File([file], sanitizedFileName, { type: file.type });

        // Lo agregamos al arreglo de Files
        this.files.push(sanitizedFile);
        this.updateFormGroup(filesControl);
      };

      reader.readAsDataURL(file);
    }
  }
  /**
   * Función para actualizar el formulario con los archivos seleccionados
   * @param filesControl variable que permite acceder a los controles de los campos
   */
  public updateFormGroup(filesControl: AbstractControl | null) {
    if (filesControl) {
      if (this.files.length > 0) {
        filesControl.setValue(this.files);
        filesControl.setErrors(null);
        this.updateName();
      } else {
        filesControl.setErrors(null);
      }
      this.updateName();
    }
  }
  /**
   * Función para actualizar el nombre de los archivos cargados
   */
  public updateName() {
    const fileCount = this.files.length;
    const pluralSuffix = fileCount !== 1 ? 's' : '';
    this.BehaviorSubjectMessageSelectFile.next(`Cargaste ${fileCount} archivo${pluralSuffix}`);
  }
  /**
   * Restablecer el estado de los archivos a su forma inicial
   */
  public resetFileState() {
    // Restablecer el estado a su forma inicial
    this.validateFiles = false;
    this.files = [];

    const filesControl = this.formGroup.get(this.filesFieldname);
    if (filesControl) {
      filesControl.setValue([]);
      filesControl.updateValueAndValidity();
    }
  }
  /**
   * Función para eliminar un archivo de la lista de archivos cargados
   */
  public removeFile(index: number) {
    this.files.splice(index, 1);
    const filesControl = this.formGroup.get(this.filesFieldname);
    this.updateFormGroup(filesControl);

    // Actualizar el nombre después de la eliminación
    this.updateName();

    // Verificar si la lista de archivos está vacía después de la eliminación
    if (this.files.length <= 0) {
      this.BehaviorSubjectMessageSelectFile.next('No hay archivos seleccionados');
      this.resetFileState();
    } else {
      this.updateName();
    }
  }
  /**
   * @description Método para verificar cuales controles son inválidos
   */
  public findInvalidControls(): string[] {
    const invalidControls: string[] = [];
    for (const name of Object.keys(this.formGroup.controls)) {
      if (this.formGroup.controls[name].invalid) {
        invalidControls.push(name);
      }
    }

    return invalidControls;
  }

  /**
   * @description Método para atender el Submit del formulario
   */
  public onSubmit(): boolean {
    this.spinner.show();
    parent.window.utag.link({
      tealium_event: 'click',
      eventLabel: 'enviar',
      eventCategory: 'formulario_nexa',
    });

    const invalidControls = this.findInvalidControls();
    this.errorTextRequestSubmit = false;
    if (invalidControls.includes('textRequest')) {
      this.errorTextRequestSubmit = true;
    }

    if (invalidControls.length > 0) {
      this.spinner.hide();

      return false;
    }

    const data: CaptchaDto = {
      googletoken: this.formGroup.value.recaptchaReactive,
    };

    this.coreFacade.postRecaptcha(data);

    return true;
  }

  /**
   * Metodo doRecaptchaReceived
   */
  public doRecaptchaReceived(reCaptcha: ReCaptchaDto) {
    this.spinner.show();
    this.tryRecaptcha = true;
    this.validRecaptcha$.next(reCaptcha.success || false);
    if (reCaptcha.isValidAuthentication) {
      const formGroupValue = this.formGroup.value;

      this.dataTransferService.flagFidu = formGroupValue.company === 1 ? 0 : 1;
      const dataModel: DataModel = {
        NumberDocument: formGroupValue.document,
        Name: formGroupValue.name || '',
        Email: formGroupValue.email || '',
        TypeClient: this.typeClients.find((x) => x.id === formGroupValue.typeClient)?.name,
        Product: this.formGroup.get('product')?.value,
        TypeRequest: this.formGroup.get('typeOfRequest')?.value,
        Attachments: this.files.length > 0 ? true : false,
        MessageClient: formGroupValue.textRequest,
        CountAttachments: this.files.length,
        Company: this.companies.find((x) => x.id === formGroupValue.company)?.name,
        IsClient: true,
        IdCLient: formGroupValue.company === 1 ? environment.occidenteIdClient : environment.fiduoccidenteIdClient,
        IdCamapaign:
          formGroupValue.company === 1 ? environment.occidenteIdCampaign : environment.fiduoccidenteIdCampaign,
      };

      // Se realizar de la interacion
      setTimeout(() => {
        this.coreFacade.saveFileStorage(this.files, (responseFilesInfo) => {

          const interactionModel: Interaction = {
            IdInteraction: 1,
            GuId: Guid.create().toString(),
            Forms: dataModel,
            Date: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString(),
            Files: responseFilesInfo,
            IdMailBox: formGroupValue.idMailbox,
            IdTemplate: formGroupValue.idTemplate,
            IdAttachmentMode: formGroupValue.idAttachmentMode,
            Attachment: TypeAttach[formGroupValue.idAttachmentMode],
          };

          const interactionDto: InteractionDto = {
            payload: `${JSON.stringify(interactionModel)}`.trim(),
            version: '1',
            idSequence: 2,
            idFiling: 1,
            publishDate: new Date(),
            typeForm: 1,
          };

          this.coreFacade.saveInteraction(interactionDto, (responseInteraction) => {
            if (responseInteraction !== 'false') {
                this.spinner.hide();
            }
          });
        });
      }, 100);

      this.formGroup.reset();
    }
  }
}
