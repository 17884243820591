<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <!--Inicio Plantilla de Alert-->

  <ng-template #Alert let-warningOptions="warningOptions">
    <div *ngIf="warningOptions" class="alert">
      <div *ngIf="warningOptions?.showIconAlert" class="icon-alert">
        <i class="bocc-alert-triangle"></i>
      </div>

      <div *ngIf="warningOptions?.texts" class="alert-caption">
        <p *ngFor="let paragraph of warningOptions?.texts" [innerHTML]="paragraph"></p>
      </div>

      <div *ngIf="warningOptions?.showCloseAlert" class="icon-close">
        <a class="btn-nano-gray-light">
          <i class="bocc-x"></i>
        </a>
      </div>
    </div>
  </ng-template>

  <!--Fin Plantilla de Alert-->

  <div fxLayout="column">
    <!--Documento-->
    <mat-form-field fxFlex="100%" hideRequiredMarker>
      <div class="form-group active">
        <mat-label class="label">Número de Documento o NIT *</mat-label>
        <input
          class="mat-input-element-input"
          matInput
          type="text"
          minlength="5"
          maxlength="12"
          formControlName="document"
          placeholder="Digitos permitidos Min(5) - Max(12)."
          [required]="formGroup.controls.document.invalid"
          (keypress)="numberOnly($event)"
        />
      </div>
    </mat-form-field>

    <div
      *ngIf="formGroup.controls.document.value?.length <= 4 && formGroup.controls.document.value?.length > 0"
      style="color: red"
    >
      El número de documento debe tener más de 4 dígitos.
    </div>

    <!--Nombres y Apellidos-->
    <mat-form-field *ngIf="visibleInputNoClient == true" fxFlex="100%" hideRequiredMarker>
      <div class="form-group active">
        <mat-label class="label">Nombres y Apellidos *</mat-label>
        <input
          class="mat-input-element-input"
          matInput
          type="text"
          formControlName="name"
          maxlength="128"
          placeholder="(Valor  permitido: Nombre Completo)."
          [required]="formGroup.controls.name.invalid"
        />
      </div>
    </mat-form-field>

    <!--Compañías-->
    <mat-form-field
      *ngIf="visibleInputNoClientFidu == false || visibleInputNoClientOcci == false"
      fxFlex="100%"
      hideRequiredMarker
    >
      <div class="form-group active">
        <mat-label class="label">Compañía</mat-label>
        <mat-select
          formControlName="company"
          [required]="formGroup.controls.company.invalid"
          (selectionChange)="onCompanyChange($event.value)"
          [disabled]="formGroup.controls.document.value?.length <= 4 || formGroup.controls.document.value?.length === 0"
        >
          <mat-option *ngFor="let company of companies$ | async" [value]="company.id">
            {{ company.name }}
          </mat-option>
        </mat-select>
      </div>
    </mat-form-field>

    <!--Correo Electronico-->
    <mat-form-field fxFlex="100%" hideRequiredMarker>
      <div class="form-group active">
        <mat-label class="label">Correo Electrónico *</mat-label>
        <input
          class="mat-input-element-input"
          matInput
          type="email"
          formControlName="email"
          maxlength="128"
          placeholder="(Valor  permitido:Ej. pruebas@pruebas.com)."
          [required]="formGroup.controls.email.invalid"
          [ngClass]="{ invalid: formGroup.get('email')?.invalid && formGroup.get('email')?.touched }"
        />
        <!-- Mensaje de alerta -->
        <div
          *ngIf="formGroup.get('email')?.invalid && formGroup.get('email')?.touched"
          class="alert-message invalid-text"
        >
          Por favor, ingresa un correo electrónico válido.
        </div>
      </div>
    </mat-form-field>

    <!--Tipo De Cliente-->
    <mat-form-field
      *ngIf="visibleInputNoClientFidu == false && visibleInputNoClientOcci == false && visibleInputTypeClient == true"
      fxFlex="100%"
      hideRequiredMarker
    >
      <div class="form-group active">
        <mat-label class="label">Tipo de Cliente *</mat-label>
        <mat-select
          formControlName="typeClient"
          placeholder="Tipo de Cliente"
          [required]="formGroup.controls.typeClient.invalid"
          (selectionChange)="onTypeClientChange($event.value)"
        >
          <mat-option *ngFor="let typeClient of typeClients$ | async" [value]="typeClient.id">
            {{ typeClient.name }}
          </mat-option>
        </mat-select>
      </div>
    </mat-form-field>

    <!--Mensaje de ayuda-->
    <div *ngIf="visibleMessage" class="alert">
      <!--Banco de Occidente-->
      <p *ngIf="message">
        Te informamos que si dentro del siguiente listado no encuentras tu inquietud, te invitamos a contactarnos para
        poder colaborarte a través de las líneas de servicio al cliente, Bogotá (601) 390 20 58, a nivel nacional
        018000514652 o WhatsApp 3186714836. <br />
        Te informamos que la respuesta a tu solicitud será enviada al correo electrónico registrado como principal ante
        el Banco
      </p>
      <!--Fiduciaria de Occidente-->
      <p *ngIf="!message">
        Te informamos que si dentro del siguiente listado no encuentras tu inquietud, te invitamos a contactarnos a
        través de las líneas de servicio al cliente en Bogotá: (601) 742 74 45, en el resto del País: 01 8000 521 144.
      </p>
    </div>
  </div>

  <div *ngIf="visibleInputNoClientOcci == true" fxLayout="column">
    <!-- Alerta -->
    <div class="alert ng-star-inserted">
      <div class="icon-alert">
        <i class="bocc-alert-triangle"></i>
      </div>
      <div class="alert-caption">
        <p>
          Creo que aún no eres nuestro cliente,
          <b></b>Por favor verifica que tu número de documento este correcto o para poder ayudarte, te invitamos por
          favor a comunicarte a nuestras líneas de atención en Bogotá <a href="tel:+5713902058"> (601) 390 20 58</a> y
          para el resto del país al <a href="tel:018000514652">018000 514 652</a> o comunícate a nuestro
          <a href="#">Chat</a>
        </p>
        <p><b>Si deseas adquirir productos con nosotros aquí te podemos </b><a href="#">ayudar</a></p>
      </div>
    </div>
  </div>
  <div *ngIf="visibleInputNoClientFidu == true" fxLayout="column">
    <!-- Alerta -->
    <div class="alert ng-star-inserted">
      <div class="icon-alert">
        <i class="bocc-alert-triangle"></i>
      </div>
      <div class="alert-caption">
        <p>
          Creo que aún no eres nuestro cliente. Por favor verifica que tu número de documento este correcto para poder
          ayudarte, te invitamos por favor a comunicarte a nuestras líneas de atención en Bogotá<a
            href="tel:+576012973060"
          >
            (601) 742 74 45</a
          >
          y para el resto del país al <a href="tel:018000189818">01 8000 189 818</a>
        </p>
        <p><b>Si deseas adquirir productos con nosotros aquí te podemos </b><a href="#">ayudar</a></p>
      </div>
    </div>
  </div>
  <ng-container *ngFor="let control of jsonFormData?.controls; let i = index">
    <!-- 'select' -->
    <app-selects
      *ngIf="control.type === 'select' && control.name"
      [siblingsControls]="jsonFormData.controls"
      [control]="control"
      [visible]="true"
      (controlAdded)="onAddControl($event)"
      [formBuilder]="formBuilder"
    >
    </app-selects>

    <div fxLayout="column">
      <!-- Alerta -->
      <ng-container
        *ngIf="control.type === 'alert' && control.value"
        [ngTemplateOutlet]="Alert"
        [ngTemplateOutletContext]="{
          warningOptions: control.alert
        }"
      >
      </ng-container>

      <!-- 'text','password','email','number','search','tel','url'-->
      <mat-form-field
        *ngIf="['text', 'password', 'email', 'number', 'search', 'tel', 'url'].includes(control.type)"
        fxFlex="100%"
        hideRequiredMarker
      >
        <div class="form-group active">
          <mat-label class="label">{{ control.label }}</mat-label>
          <input
            matInput
            [type]="control.type"
            [formControlName]="control.name"
            [placeholder]="control.label"
            [value]="control.value"
            [disableControl]="control.disabled"
          />
        </div>
      </mat-form-field>

      <!-- 'textarea'-->
      <mat-form-field *ngIf="control.type === 'textarea'" fxFlex="100%" hideRequiredMarker>
        <div class="form-group active">
          <mat-label class="label">{{ control.label }}</mat-label>
          <textarea
            matInput
            rows="3"
            [formControlName]="control.name"
            [placeholder]="control.label"
            minlength="10"
            maxlength="750"
            [value]="control.value"
            [disableControl]="control.disabled"
          ></textarea>
        </div>
      </mat-form-field>

      <!-- 'Request'-->
      <mat-form-field *ngIf="dataTransferService.showRequest" fxFlex="100%" hideRequiredMarker>
        <div class="form-group active">
          <mat-label class="label">¿Cómo te podemos ayudar? *</mat-label>
          <textarea
            class="mat-input-element-textarea"
            matInput
            rows="3"
            formControlName="textRequest"
            minlength="10"
            maxlength="750"
            placeholder="Escribe tu solicitud aquí Min(10)-Máx(750) caracteres."
            (keyup)="count()"
            [ngClass]="{
              'invalid-textarea': formGroup.get('textRequest')?.invalid && formGroup.get('textRequest')?.touched
            }"
          >
          </textarea>

          <!-- Mensaje de alerta -->
          <div class="count-char">
            <span>{{ this.valor }}|750</span>
          </div>
          <div
            *ngIf="formGroup.get('textRequest')?.invalid && formGroup.get('textRequest')?.touched"
            class="alert-message alert-text"
          >
            Por favor, ingresa como mínimo 10 caracteres.
          </div>
        </div>
      </mat-form-field>

      <!-- Sección para cargue de archivos -->
      <div *ngIf="showInputFile" title="">
        <div
          class="fileUpGroup"
          attr.data-textButton="{{ messageButton }}"
          attr.data-SelectFile="{{ messageSelectFile$ | async }}"
        >
          <input
            type="file"
            class="fileUpload"
            (change)="onFileChange($event)"
            multiple
            [ngClass]="{ 'invalid-input': validateFiles && !files.length }"
          />
        </div>
        <div *ngIf="files.length > 0">
          <div class="file-item" *ngFor="let file of files; index as i">
            <p class="file-info">
              {{ file.name }}
              <button mat-icon-button class="delete-button" (click)="removeFile(i)">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </p>
          </div>
        </div>
      </div>
      <!-- Aquí se muestra el mensaje de advertencia debajo de la lista de archivos -->
      <div *ngIf="validateFiles">
        <p class="warning">{{ messageValidateFiles }}</p>
      </div>

      <!--
        <mat-form-field fxFlex="100%" hideRequiredMarker>
        </mat-form-field>
      -->
      <div class="form-group active">
        <re-captcha class="g-recaptcha center-align" formControlName="recaptchaReactive"></re-captcha>
      </div>
      <mat-error *ngIf="!(validRecaptcha$ | async) && tryRecaptcha"> ¡El reCaptcha es Inválido! </mat-error>
    </div>
  </ng-container>

  <button mat-raised-button [disabled]="formGroup.invalid" class="button-border">
    <p [ngClass]="formGroup.invalid ? 'button button-disabled' : 'button button-primary'">Enviar</p>
  </button>
</form>

<ngx-spinner bdColor="rgba(0,0,0,0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p class="spinner-text">Cargando...</p>
</ngx-spinner>
