import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'apps/form-contact/src/environments/environment';
import { Observable } from 'rxjs';

import { FileDto } from '../models/file.dto';

/**
 * Servicio para el cargue de archivo al Storage
 */
@Injectable({
  providedIn: 'root',
})
export class FileService {
  /**
   * Performs HTTP requests. This service is available as an injectable class,
   * with methods to perform HTTP requests. Each request method has multiple signatures,
   * and the return type varies based on the signature that is called (mainly the values of observe and responseType).
   */
  private readonly http: HttpClient;
  /**
   * Url del servicio
   */
  private readonly apiUrl: string | undefined;
  /**
   * Objeto FormData para almacenar los datos del archivo
   */
  public formData: FormData = new FormData();
  /**
   * Constructor del servicio
   * @param http Performs HTTP requests. This service is available as an injectable class
   */
  public constructor(http: HttpClient) {
    this.http = http;
    this.apiUrl = environment.apis.file;
  }

  /**
   * Método encargado de consultar el envio de correos
   * informacion almacenada en los claims de identity server y retornar la información
   * @returns Objeto de tipo @see FileDto con la información de envio correo
   */
  public PostUpdateFiles(files: File[]): Observable<FileDto[]> {
    if (files) {
      // Configurar FormData
      this.formData = new FormData();

      // Agregar los datos de la interfaz al formData
      for (const file of files) {
        this.formData.append('files', file);
      }
    }

    // Enviar la solicitud HTTP POST
    return this.http.post<FileDto[]>(`${this.apiUrl}/File/Upload/1?idCampaign=1&idUser=1&overwrite=false&target=1`, this.formData);
  }
}
