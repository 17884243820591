import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { JsonFormControls } from './models/json-form-data';

/**
 * @description Permite agregar un FormControl a un Fromulario
 *
 * @param formBuilder Creador del Formulario
 *
 * @param FormGroup Agrupador del Formulario
 *
 * @param control Control a ser agregado
 */
export const newControl = (formBuilder: FormBuilder, form: FormGroup, control: JsonFormControls) => {
  form.addControl(control.name, formBuilder.control(control.value, getValidators(control)));
  control.visible = true;
};

/**
 * @description Retorna los posibles validadores de un FormControl
 *
 * @param control Control que proporciona información de los validadores
 */

export const getValidators = (control: JsonFormControls) => {
  const validatorsToAdd = [];

  if (control.validators) {
    for (const [key, value] of Object.entries(control.validators)) {
      switch (key) {
        case 'min':
          validatorsToAdd.push(Validators.min(value as number));
          break;
        case 'max':
          validatorsToAdd.push(Validators.max(value as number));
          break;
        case 'required':
          if (value) {
            validatorsToAdd.push(Validators.required);
          }
          break;
        case 'requiredTrue':
          if (value) {
            validatorsToAdd.push(Validators.requiredTrue);
          }
          break;
        case 'email':
          if (value) {
            validatorsToAdd.push(Validators.email);
          }
          break;
        case 'minLength':
          validatorsToAdd.push(Validators.minLength(value as number));
          break;
        case 'maxLength':
          validatorsToAdd.push(Validators.maxLength(value as number));
          break;
        case 'pattern':
          validatorsToAdd.push(Validators.pattern(value as string));
          break;
        case 'nullValidator':
          if (value) {
            validatorsToAdd.push(Validators.nullValidator);
          }
          break;
        default:
      }
    }
  }

  return validatorsToAdd;
};

/**
 * @description Retorna un número con tamaño fijo, rellenado de ceros a la izquierda
 *
 * @param num número
 * @param size tamaño en caractes del número
 */
export const padNumber = (num: number, size: number): string => {
  let s = `${num}`;
  while (s.length < size) { s = `0${s}`; }

  return s;
};

/**
 * @description Retorna un string con un formato personalizado
 *
 * @param date fecha
 */
export const formatDateTime = (date: Date): string => {

  const dt = new Date(date) ;
  const day = (`0${dt.getDate()}`).slice(-2);
  const month = (`0${dt.getMonth() + 1}`).slice(-2);
  const year = dt.getFullYear();
  const hour = (`0${dt.getHours()}`).slice(-2);
  const minute = (`0${dt.getMinutes()}`).slice(-2);
  const second = (`0${dt.getSeconds()}`).slice(-2);
    // FORMATO FECHA: 2022-02-21T21:04:44.6603913-05:00-0007

  const datetime = `${year}${month}${day}_${hour}${minute}${second}`;

  return datetime;
};
