<form [formGroup]="formGroupFc" (ngSubmit)="onSubmit()">
  <!-- Sección para cargue de archivos -->
  <div *ngIf="validateFiles === false">
    <p class="text-primary">Adjunta tus soportes aquí(Solo JPG, PDF, TIFF y JPEG, máximo 25 MB)</p>
  </div>
  <div *ngIf="showInputFile" title="">
    <div
      class="fileUpGroup"
      attr.data-textButton="{{ messageButton }}"
      attr.data-SelectFile="{{ messageSelectFile$ | async }}"
    >
      <input
        type="file"
        class="fileUpload"
        (change)="onFileChange($event)"
        multiple
        [ngClass]="{ 'invalid-input': validateFiles && !files.length }"
      />
    </div>
    <div *ngIf="files.length > 0">
      <div class="file-item" *ngFor="let file of files; index as i">
        <p class="file-info">
          {{ file.name }}
          <button mat-icon-button class="delete-button" (click)="removeFile(i)">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </p>
      </div>
    </div>
  </div>
  <!-- Aquí se muestra el mensaje de advertencia debajo de la lista de archivos -->
  <div *ngIf="validateFiles">
    <p class="warning">{{ messageValidateFiles }}</p>
  </div>

  <div class="form-group active">
    <re-captcha class="g-recaptcha center-align" formControlName="recaptchaReactive"></re-captcha>
  </div>
  <mat-error *ngIf="!(validRecaptcha$ | async) && tryRecaptcha"> ¡El reCaptcha es Inválido! </mat-error>
  <div class="text-primary">
    <p>Al hacer clic en el botón enviar, se acepta la remisión de la PQRS a la Fiduciaria de Occidente.</p>
    <br />
    <p>Tus datos serán recolectados y tratados conforme con la Política de Tratamiento de Datos.</p>
    <br />
    <p>
      En caso de que la solicitud de información sea de naturaleza de identidad reservada, se deberá efectuar el
      respectivo trámite ante la Procuraduría General de la Nación, haciendo clic en el siguiente link:
      <a href="https://sedeelectronica.procuraduria.gov.co/PQRDSF/" target="_blank" rel="noopener noreferrer"
        >sedeelectronica.procuraduria.gov.co/PQRDSF/</a
      >
    </p>
  </div>
  <br />
  <button mat-raised-button [disabled]="formGroupFc.invalid" class="button-border">
    <p [ngClass]="formGroupFc.invalid ? 'button button-disabled' : 'button button-primary'">Enviar</p>
  </button>
</form>
