import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/**
 * Matriz de rutas base (inicial)
 */
export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./core/core.module').then((x) => x.CoreModule),
  }];

/**
 * Modulo de enrutamiento base de angular
 */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
