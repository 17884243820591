import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@nexa/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { NgxSpinnerModule } from 'ngx-spinner';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule as FormContactCoreModule } from './core/core.module';
import { TealiumUtagService } from './tealium/utag.service';

/**
 * Modulo Principal de Angular
 */
@NgModule({
  declarations: [AppComponent],
  imports: [
    AuthModule.forRoot(),
    BrowserModule,
    NgxSpinnerModule,
    MatIconModule,
    BrowserAnimationsModule,
    FormContactCoreModule,
    CoreModule.forRoot({
      environment,
    }),
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
  ],
  providers: [TealiumUtagService],
  bootstrap: [AppComponent],
})
export class AppModule {}
