import { Component, OnInit } from '@angular/core';
import { RequestUrlDto } from '@bdo/request-url';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreFacade } from '../../core.facade';
import { CoreState } from '../../state/core.state';

/**
 * Componente Angular
 */
@Component({
  selector: 'app-social-networks',
  templateUrl: './social-networks.component.html',
  styleUrls: ['./social-networks.component.scss'],
})
/**
 * Clase SocialNetworksComponent
 */
export class SocialNetworksComponent implements OnInit {
  /**
   * CoreFacade
   */
  private readonly facade: CoreFacade;
  /**
   * CoreState
   */
  private readonly state: CoreState;
  /**
   * Observable requestUrl
   */
  public requestUrl$: Observable<RequestUrlDto[]> = new Observable();
  /**
   * Spinner Service
   */
  public readonly spinner: NgxSpinnerService;
  /**
   * link de facebook
   */
  public facebook = '';
  /**
   * link de Twitter
   */
  public twitter = '';
  /**
   * link de Linkedin
   */
  public linkedin = '';
  /**
   * link de youtube
   */
  public youtube = '';
  /**
   * Constructor del componente
   * @param facade Permite acceder a las propiedades del facade
   * @param state Permite acceder a las propiedades del state
   * @param spinner Permite utilizar el spinner
   */
  public constructor(facade: CoreFacade, state: CoreState, spinner: NgxSpinnerService) {
    this.facade = facade;
    this.state = state;
    this.spinner = spinner;
    this.requestUrl$ = this.state.requestUrl$;
  }

  /**
   * Servicio que se ejecuta al iniciar el componente
   */
  public ngOnInit(): void {
    this.spinner.show();
    this.facade.getRequestUrl(() => {
      this.requestUrl$.pipe(takeUntil(this.facade.destroy$)).subscribe((response) => {
        response.forEach((element) => {
          switch (element.name) {
            case 'Facebook':
              this.facebook = element.url || '';
              break;
            case 'Twitter':
              this.twitter = element.url || '';
              break;
            case 'Linkedin':
              this.linkedin = element.url || '';
              break;
            case 'Youtube':
              this.youtube = element.url || '';
              break;
            default:
              console.warn(`URL for ${element.name} is not handled.`);
          }
          this.spinner.hide();
        });
      });
    });
  }
}
