import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CarrouselApiService } from '@bdo/carrousel';
import { CaptchaApiService, InteractionApiService, SettingApiService } from '@bdo/interaction';
import { RequestUrlApiService } from '@bdo/request-url';
import { SendEmailApiService } from '@bdo/send-email';
import {
  CityApiService,
  CompanyApiService,
  DepartmentApiService,
  TypeClientApiService,
  TypologyApiService,
} from '@bdo/typology';
import {
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_SETTINGS,
} from 'ng-recaptcha';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxSpinnerModule } from 'ngx-spinner';

import { environment } from '../../environments/environment';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { AuthInterceptorService } from '../services/auth-interceptor.service';
import { AuthenticationService } from '../services/authentication.service';
import { DataTransferService } from '../services/data-transfer.service';
import { EncryptService } from '../shared/services/encyrpt/encrypt.service';

import { CarouselComponent } from './components/carousel/carousel.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { CustomerPrivacyComponent } from './components/customer-privacy/customer-privacy.component';
import { DialogPopupComponent } from './components/dialog-popup/dialog-popup.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormComponent } from './components/form/form.component';
import { HeaderComponent } from './components/header/header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { SocialNetworksComponent } from './components/social-networks/social-networks.component';
import { CoreRoutingModule } from './core-routing.module';
import { CoreFacade } from './core.facade';
import { CoreState } from './state/core.state';

/**
 * Decorador ngModule
 */
@NgModule({
  declarations: [
    SocialNetworksComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    ContactsComponent,
    FormComponent,
    CarouselComponent,
    DialogPopupComponent,
    CustomerPrivacyComponent,
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    DynamicFormModule,
    FlexLayoutModule,
    FormsModule,
    NgxSpinnerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSidenavModule,
    MatToolbarModule,

    ReactiveFormsModule,
    CarouselModule,
    RecaptchaFormsModule,
    RecaptchaModule,
  ],
  exports: [],
  providers: [
    DataTransferService,
    EncryptService,

    CoreFacade,
    CoreState,
    CompanyApiService,
    TypeClientApiService,
    TypologyApiService,
    CityApiService,
    DepartmentApiService,

    CaptchaApiService,
    InteractionApiService,
    SettingApiService,

    SendEmailApiService,
    AuthenticationService,
    RequestUrlApiService,
    CarrouselApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.googleCaptcha } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'es',
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {}
