import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

/**
 * Habilita el modo de producción si la aplicación está en modo producción
 */
if (environment.production) {
  enableProdMode();
}

/**
 * Inicia la aplicación Angular bootstraping el módulo principal
 */
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
/**
 * namespace global
 */
declare global {
/**
 * Extends the Window interface to include the utag property.
 */
interface Window {
  /**
   * The utag property can hold any type of value.
   * It is typically used to store tracking information for analytics.
   */
  utag: any;
}
}
