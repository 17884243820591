<owl-carousel-o [options]="customOptions" class="div-carousel">
  <ng-container *ngFor="let slide of carrousel$ | async">
    <ng-template class="slide" carouselSlide [id]="(slide.id || '').toString()">
      <div class="img-responsive" [innerHTML]="slide.base64"></div>
    </ng-template>
  </ng-container>
</owl-carousel-o>
<ngx-spinner bdColor="rgba(107, 109, 134,0.2)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p class="spinner-text">Cargando...</p>
</ngx-spinner>
