import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_SETTINGS,
} from 'ng-recaptcha';
import { NgxSpinnerModule } from 'ngx-spinner';

import { environment } from '../../environments/environment';

import { FilesCaptchaComponent } from './components/files-captcha/files-captcha.component';
import { JsonFormFoComponent } from './components/json-form-fo/json-form-fo.component';
import { JsonFormComponent } from './components/json-form/json-form.component';
import { SelectsComponent } from './components/selects/selects.component';
import { DisableControlDirective } from './disable-control.directive';
import { DynamicFormRoutingModule } from './dynamic-form-routing.module';

/**
 * Decorador NgModule del dynamic-form-module
 */
@NgModule({
  declarations: [
    DisableControlDirective,
    JsonFormComponent,
    SelectsComponent,
    JsonFormFoComponent,
    FilesCaptchaComponent,
  ],
  exports: [JsonFormComponent, JsonFormFoComponent, SelectsComponent, FilesCaptchaComponent],
  imports: [
    CommonModule,
    DynamicFormRoutingModule,
    FlexLayoutModule,
    FormsModule,

    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatSidenavModule,
    MatToolbarModule,
    NgxSpinnerModule,
    RecaptchaFormsModule,
    RecaptchaModule,

    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.googleCaptcha } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'es',
    },
  ],
})
export class DynamicFormModule {}
